import merge from 'lodash-es/merge'
import type { RecursiveKeyOf, TranslationsType } from '~/translations/master'
import translationsFallback from '~/translations/master'

export interface Translations {
  [key: string]: string | Translations
}

type TranslationKeyType = RecursiveKeyOf<typeof TranslationsType>
export type { TranslationKeyType as TranslationKey }

declare global {
  type TranslationKey = TranslationKeyType
}

export interface TranslationParams {
  [key: string]: string
}

export type TranslateFn = {
  /**
   * Mimics {@link https://vue-i18n.intlify.dev/api/injection.html#t-key-plural | `$t(key, plural)`} function of {@link https://github.com/intlify/vue-i18n | @intlify/vue-i18n}.
   *
   * @todo Remove this function after final transition to {@link https://github.com/intlify/vue-i18n | @intlify/vue-i18n}.
   */
  (key: TranslationKey, plural?: number): string
  /**
   * Mimics {@link https://vue-i18n.intlify.dev/api/injection.html#t-key-named-plural | `$t(key, named, plural)`} function of {@link https://github.com/intlify/vue-i18n | @intlify/vue-i18n}.
   *
   * @todo Remove this function after final transition to {@link https://github.com/intlify/vue-i18n | @intlify/vue-i18n}.
   */
  (key: TranslationKey, named?: TranslationParams, plural?: number): string
}

export type L10NContext = {
  readonly locale: string
  readonly language: string
  translations: typeof TranslationsType
  translate(
    key: TranslationKey,
    params?: TranslationParams,
    count?: number,
  ): string
  setPageTranslations(
    newTranslations: Translations,
    newLocale: string,
    newLanguage: string,
  ): void
}

export default defineNuxtPlugin({
  name: 'app:i18n',
  enforce: 'pre',
  dependsOn: [],
  setup(nuxtApp) {
    //   const combineTranslations = (translationsObj: Translations) => translationsObj

    /**
     * During development fallback to master translations is available
     * due to missing translations in CMS
     * This functionality is not needed in production
     */
    /*
    if (import.meta.dev) {
      const { fallback } = useRuntimeConfig().public.translations
      const baseTranslations = fallback ? (await import('~/translations/master')).default : {}
      combineTranslations = (translationsObj: Translations) => merge({}, baseTranslations, translationsObj)
    }
      */

    const pageTranslations = ref<Translations>({})
    const pageLocale = useState<string>()
    const pageLanguage = useState<string>()

    const locale = computed(() => pageLocale.value || '')
    const language = computed(() => pageLanguage.value || '')

    // const translations = computed(() => combineTranslations(pageTranslations.value) as typeof TranslationsType)

    const translations = computed(
      () =>
        merge(
          {},
          useRuntimeConfig().public.translations.fallback
            ? translationsFallback
            : {},
          useConfdata().translations,
        ) as typeof TranslationsType,
    )

    const translationMap = computed(() =>
      createTranslationMap(translations.value),
    )

    const $t: TranslateFn = (
      key: TranslationKey,
      params?: number | TranslationParams,
      plural?: number,
    ) => {
      return typeof params === 'number'
        ? getTranslation(translationMap.value, key, {}, params)
        : getTranslation(translationMap.value, key, params, plural)
    }

    const $l10n = reactive({
      locale,
      language,
      translations,
      translate: $t,
      setPageTranslations(
        newTranslations: Translations,
        newLocale: string,
        newLanguage: string,
      ) {
        pageTranslations.value = newTranslations
        pageLocale.value = newLocale
        pageLanguage.value = newLanguage
      },
    })

    const $i18nMimic = reactive({
      t: $t,
      locale,
    })

    nuxtApp.provide('i18n', $i18nMimic)
    nuxtApp.provide('t', $t)
    nuxtApp.provide('l10n', $l10n)
  },
})

type I18NMimic = {
  t: TranslateFn
  locale: string
}

declare module '#app' {
  interface NuxtApp {
    readonly $t: TranslateFn
    readonly $l10n: L10NContext
    readonly $i18n: I18NMimic
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    readonly $t: TranslateFn
    readonly $l10n: L10NContext
    readonly $i18n: I18NMimic
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    readonly $t: TranslateFn
    readonly $l10n: L10NContext
    readonly $i18n: I18NMimic
  }
}
