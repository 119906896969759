export default defineNuxtRouteMiddleware((to, _) => {
  if (to.path.startsWith('/precheckins/iframepages')) {
    return
  }

  useParams()._init(to.query)

  // Skip server side
  if (import.meta.server) return

  // Get the language from ssr and set it also in the client, if there is a mismatch -> otherwise the language parameter gets lost when its not set in the original to.query route
  const language =
    (useParams().language as string) || useReservation().reservation?.language

  // Set the parameter language in the query if it is not set on the client side, but on the server side
  if (language && language !== to.query.language) {
    // Note: the navigate redirect somehow only works on the top level middleware, not in sub middlewares
    return navigateTo({ path: to.path, query: { ...to.query, language } })
  }
})
