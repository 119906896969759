export const usePaymentStore = defineStore('payment', () => {
  const paymentOption: Ref<SelectedPaymentOption | null> = ref(null)

  // sets the current selected payment option
  function setPaymentOption(paymentMethod: string, prePayment: boolean) {
    paymentOption.value = {
      paymentMethod,
      prePayment,
    }
  }

  return {
    paymentOption,
    setPaymentOption,
  }
})
