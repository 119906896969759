import type { LocationQuery } from '#vue-router'
import { parseQuery } from 'ufo'

export function unpack(obj: Params): PlainParams {
  const result: PlainParams = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(result, unpack(value))
    } else if (value) {
      result[key] = value
    }
  })

  return result
}

export function asQueryParams(params: PlainParams): Record<string, string> {
  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, value.toString()]),
  )
}

// Converts a URLSearchparams object to a query object that can be used in a fetch request
export function getQueryObjectFromURLParams(urlParams: URLSearchParams) {
  const paramsString = urlParams.toString()
  const query = parseQuery(paramsString)

  return query
}

// Converts a query object to a URLSearchParams object that can be used in a fetch request
export function getQueryStringFromObject(query: LocationQuery, encode = false) {
  let queryString = ''
  const keys = Object.keys(query)
  keys.forEach((key, index) => {
    if (index === 0) {
      queryString += '?'
    }
    queryString += `${key}=${query[key]}`
    if (index < keys.length - 1) {
      queryString += '&'
    }
  })

  if (encode) {
    queryString = encodeURIComponent(queryString)
  }

  return queryString
}

// Computes a path combined with the query string to navigate back to the home page
export function getHomeNavigation(path: string, query: LocationQuery) {
  // reomve the language from the query and also the returnTo parameter
  delete query.language
  delete query.returnTo

  const queryString = getQueryStringFromObject(query)
  const combinedPath = `${path}${queryString}`
  return combinedPath
}
