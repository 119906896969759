import { toast } from 'vue3-toastify'
import { useStorage } from '@vueuse/core'
import WebccInterchaletBrandMergeToaster from '~/components/@ihgroup/components/WebccInterchaletBrandMergeToaster.vue'
import AddToAccountToaster from '~/components/@ihgroup/components/AddToAccountToaster.vue'

type ToasterFunction = (text: string, options?: ToastOptions) => void

export default function useToaster() {
  function _toast(type: ToastType, text: string, options?: ToastOptions) {
    toast(text, {
      type,
      delay: options?.delay,
      autoClose: options?.duration ?? 4000,
    })
  }

  function error(text: string, options?: ToastOptions) {
    _toast('error', text, options)
  }

  function warning(text: string, options?: ToastOptions) {
    _toast('warning', text, options)
  }

  function success(text: string, options?: ToastOptions) {
    _toast('success', text, options)
  }

  function info(text: string, options?: ToastOptions) {
    _toast('info', text, options)
  }

  function untyped(text: string, options?: ToastOptions) {
    _toast('default', text, options)
  }

  function selectToasterFn(type: ToastType): ToasterFunction {
    const functions: Record<ToastType, ToasterFunction> = {
      ['success']: success,
      ['warning']: warning,
      ['info']: info,
      ['default']: untyped,
      ['error']: error,
    }

    return functions[type]
  }

  function saveToast(
    path: string,
    toast: { type: ToastType; text: string; options?: ToastOptions },
  ) {
    const savedToasts = useStorage<SavedToastItem[]>('saved-toasts', [])
    savedToasts.value.push({ path, toast })
  }

  function showSavedToasts(path: string) {
    const savedToasts = useStorage<SavedToastItem[]>('saved-toasts', [])
    const filteredToasts = savedToasts.value.filter(
      (toastItem) => toastItem.path === path,
    )

    filteredToasts.forEach((toastItem) => {
      _toast(
        toastItem.toast.type,
        toastItem.toast.text,
        toastItem.toast.options,
      )
    })

    const remainingToasts = savedToasts.value.filter(
      (toastItem) => toastItem.path !== path,
    )
    savedToasts.value = remainingToasts
  }

  function interhome(options?: ToastOptions) {
    toast(() => h(WebccInterchaletBrandMergeToaster), {
      autoClose: options?.duration ?? 5000,
      closeButton: true,
      ...options,
    })
  }

  function addToAccount(
    isSuccess: boolean,
    withLink: boolean = false,
    errorTemplate?: string | null | undefined,
  ) {
    setTimeout(() => {
      toast(
        () => h(AddToAccountToaster, { isSuccess, withLink, errorTemplate }),
        {
          autoClose: 5000,
          closeButton: true,
          type: isSuccess ? 'success' : 'error',
        },
      )
    }, 100)
  }

  return {
    error,
    warning,
    success,
    info,
    untyped,
    selectToasterFn,
    addToAccount,
    saveToast,
    showSavedToasts,
    interhome,
  }
}
