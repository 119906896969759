<template>
  <footer class="bg-bgr-300 mt-auto flex-shrink-0">
    <div
      class="flex flex-col items-center py-6 container-fluid-lg md:flex-row md:py-20"
    >
      <WebccCompanyLogo
        href=""
        :caption="logoCaption"
        class="mb-8 md:mb-0 md:mr-12 md:w-1/3 md:self-center lg:w-1/4"
      />
      <section class="flex flex-col md:flex-grow md:self-start mb-8 md:mb-0">
        <a
          v-if="footerLinks?.conditions"
          target="_blank"
          :href="footerLinks.conditions.href"
          :title="footerLinks.conditions.label"
          class="block w-max text-txt-strong hover:text-thm"
        >
          {{ footerLinks.conditions.label }}
        </a>
        <a
          v-if="footerLinks?.privacyPolicy"
          target="_blank"
          :href="footerLinks.privacyPolicy.href"
          :title="footerLinks.privacyPolicy.label"
          class="block w-max text-txt-strong hover:text-thm"
        >
          {{ footerLinks.privacyPolicy.label }}
        </a>
        <button
          :title="$t(`${trPrefix}cookieSettings`)"
          class="block w-max text-left text-txt-strong hover:text-thm"
          @click="useState('consentConfigOpen').value = true"
        >
          {{ $t(`${trPrefix}cookieSettings`) }}
        </button>
        <a
          v-if="footerLinks?.imprint"
          target="_blank"
          :href="footerLinks.imprint.href"
          :title="footerLinks.imprint.label"
          class="block w-max text-txt-strong hover:text-thm"
        >
          {{ footerLinks.imprint.label }}
        </a>
      </section>
      <section
        class="flex-end flex flex-col justify-between items-center md:items-end"
      >
        <div class="flex flex-wrap justify-between gap-4 mb-6 md:mb-4">
          <img
            src="~/assets/images/quality-check.png"
            :alt="$t(`${trPrefix}qualityCheckAlt`)"
            :title="$t(`${trPrefix}qualityCheckAlt`)"
          />
          <img
            src="~/assets/images/digicert-trusted.png"
            :alt="$t(`${trPrefix}SSLCertificateAlt`)"
            :title="$t(`${trPrefix}SSLCertificateAlt`)"
          />
          <p
            class="flex max-w-[7rem] flex-col flex-wrap justify-center text-center text-xs"
          >
            <span>
              {{ $t(`${trPrefix}memberMigros`) }}
            </span>
            <span class="font-medium text-[#e3672a]">
              {{ $t(`${trPrefix}migros`) }}
            </span>
          </p>
        </div>
        <div class="text-right text-txt-strong">
          {{
            $t(`${trPrefix}copyright`, {
              year: new Date().getFullYear().toString(),
            })
          }}
        </div>
      </section>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const trPrefix = 'guestWorld.footer.'

defineProps<{
  footerLinks?: Links
  logoCaption?: string
}>()
</script>
