export const usePartnerStore = defineStore('partner', () => {
  const id = ref('')
  const extra = ref('')
  const jwt = ref('')
  const directEncashment = ref(false)
  const language = ref('')
  const salesoffice = ref('')

  async function init() {
    // 1. JWT-Partner
    // 2. GET-Parameter
    // 3. check: req.headers.referer (wenn keine SEA-PartnerID)
    //           partnerIDfromReferrer(referrer)
    // 4. aus Cookie übernehmen
    // jetzt haben wir eine PartnerID
    // Bei Abweichung vom Cookie-Wert wird Cookie nach folgenden Regeln überschrieben: HHDWEBCC-3696

    const partner: {
      id?: string
      extra?: string
      directEncashment?: boolean
      language?: string
      salesoffice?: string
    } = {}
    const params = useParams().persistent
    const cookies = useCookies()
    const jwt = cookies.jwt || params.jwt
    if (jwt) {
      try {
        const details = await $fetch<Partner>('/webcc-api/v1/partners', {
          headers: { jwt: jwt.toString() },
        })
        Object.assign(partner, {
          id: details?.id,
          directEncashment: details?.directEncashment,
          language: details?.language,
          salesoffice: details?.salesoffice,
          jwt,
        })
        useCookie('jwt', {
          secure: true,
          sameSite: 'none',
          expires: hoursAdd(new Date(), 12),
          path: '/',
          httpOnly: true,
        }).value = jwt.toString()
      } catch (error) {
        console.error('Error during B2B-Partner-Kommunikation mit API: ', error)
      }
    } else {
      if (!partner.id && params.partnerid) {
        // PartnerID aus GET-Param lesen
        partner.id = params.partnerid.toString()
        if (params.partnerextra) {
          partner.extra = params.partnerextra.toString()
        }
      }

      if (partner.id && !partnerIDsWhitelist.includes(partner.id)) {
        // nur wenn eine evtl. vorhandene PartnerID !== der SEA-PartnerID ist wollen wir folgenden Check:
        const pid = partnerIDfromReferrer(useRequestHeaders().referer)
        if (pid !== '') {
          // Suchmaschine-PartnerID übernehmen
          partner.id = pid
        }
      }

      const partnerCookie = (cookies.partner || '').split('&')

      if (!partner.id && cookies.partner) {
        partner.id = partnerCookie[0]
        if (partnerCookie.length > 1) {
          partner.extra = partnerCookie[1]
        }
      }
      // Cookie nur setzen wenn partnerid unterschiedlich zu cookiepartnerid
      if (partner.id && partner.id !== partnerCookie[0]) {
        useCookie('partner', {
          secure: true,
          sameSite: 'none',
          expires: daysAdd(new Date(), 30),
          path: '/',
          httpOnly: true,
        }).value = partner.id
      }
    }

    id.value = partner.id || ''
    extra.value = partner.id || ''
    directEncashment.value = partner.directEncashment || false
    language.value = partner.language || ''
    salesoffice.value = partner.salesoffice || ''
  }

  return { id, extra, directEncashment, salesoffice, language, jwt, init }
})

function partnerIDfromReferrer(referrer: string) {
  if (!referrer) {
    return ''
  }
  if (referrer.includes('google.')) {
    return 'IH1000116'
  }
  const subtrings = [
    'bing.',
    'ecosia.org',
    'duckduckgo.',
    'yahoo.',
    'baidu.',
    'seznam.',
    'qwant.com',
    'yandex.',
  ]

  for (const substring of subtrings) {
    if (referrer.includes(substring)) {
      return 'IH1000117'
    }
  }
  return ''
}

// https://jira.hotelplan.com/browse/HHDWEBCC-4679
const partnerIDsWhitelist = [
  'IH1000019',
  'IH1000020',
  'IH1000021',
  'IH1000022',
  'IH1000023',
  'IH1000024',
  'IH1000025',
  'IH1000026',
  'IH1000027',
  'IH1000028',
  'IH1000029',
  'IH1000030',
  'IH1000031',
  'IH1000032',
  'IH1000033',
  'IH1000034',
  'IH1000035',
  'IH1000036',
  'IH1000223',
  'CH1001778',
]
