import * as Sentry from '@sentry/vue'
import { beforeSend } from '~/utils/errors'

export default defineNuxtPlugin((nuxtApp) => {
  const { enabled, dsn, environment, tracesSampleRate } =
    useRuntimeConfig().public.sentry

  if (!enabled) return
  if (!dsn)
    return console.warn(
      'Sentry DSN not set, skipping Sentry initialization from client',
    )

  console.log('Sentry DSN set, initializing Sentry from client')

  Sentry.init({
    beforeSend,
    dsn,
    environment,
    app: nuxtApp.vueApp,
    integrations: [
      Sentry.browserTracingIntegration({ router: useRouter() }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: tryParseFloat(tracesSampleRate) ?? 0.1,
    replaysSessionSampleRate: 0, // no need to store all session replay, only on errors
    replaysOnErrorSampleRate: 0.3,
    denyUrls: [
      /google/i,
      /bugherd\.com/i,
      /sentry\.io/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    ignoreErrors: ['cookieDomain is null'],
  })
})
