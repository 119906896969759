import type { RouteLocationNormalized } from 'vue-router'

export const useConfdataStore = defineStore('confdata', () => {
  const l10n = useL10N()

  const raw: Ref<ConfAPIPagesResponse | null> = ref(null)

  const domain: Ref<Domain> = computed(() => raw.value?.conf?.domain as Domain)
  const domainInEnvironment: Ref<string> = computed(
    () => raw.value?.conf?.domainInEnvironment as string,
  )
  const currencies = computed(() => raw.value?.conf?.currencies)

  const currency = computed(
    () => useParams().currency || raw.value?.conf?.currencies[0] || 'EUR',
  )

  const pagetype = computed(() => raw.value?.pagetype)
  const page = computed(() => raw.value?.page)
  const salesoffice = computed(() => raw.value?.conf?.salesoffice)
  const navigation = computed(() => raw.value?.conf)
  const siteSettings = computed(() => raw.value?.conf?.settings)
  const header = computed(() => raw.value?.conf?.header)
  const footer = computed(() => raw.value?.conf?.footer)
  const links = computed(() => raw.value?.conf?.links)
  const socialMedia = computed(() => raw.value?.conf?.footer.socialMedia)
  const partnerships = computed(() => raw.value?.conf?.footer.partnerships)
  const paymentForms = computed(() => raw.value?.conf?.salesoffice.paymentForms)
  const siteCode = computed(() => raw.value?.conf?.siteCode)
  const body = computed(() => raw.value?.body)
  const seoLinks = computed(() => raw.value?.seoLinks)
  const searchPrefix = computed(() => raw.value?.conf?.prefixSearchpage)
  const translations = computed(() => raw.value?.translations)
  const languagePrefix = computed(() => raw.value?.languageprefix || '')
  const locale = computed(() => raw.value?.locale)
  const language = computed(() => raw.value?.language)
  const domainsCountryCode = computed(() => raw.value?.conf?.domainsCountryCode)
  const destination = computed(() => {
    const result = { ...raw.value?.destination }
    if (result.regionCode) {
      result.regionCodeFull = result.countryCode + result.regionCode
    }
    if (result.placeCode) {
      result.placeCodeFull = result.countryCode + result.placeCode
    }
    return result
  })
  const sites = computed(() => raw.value?.conf?.sites)
  const logoCaption = computed(() => raw.value?.conf?.logoCaption)
  const pageID = computed(() => raw.value?.conf?.pageID)
  const prefixReviewspage = computed(() => raw.value?.conf?.prefixReviewspage)

  function update(data: ConfAPIPagesResponse | null) {
    raw.value = data
  }

  async function load(
    to?: RouteLocationNormalized,
    from?: RouteLocationNormalized,
    params: URLSearchParams = new URLSearchParams(),
  ) {
    if (from?.path === to?.path && raw.value) return

    try {
      const url = useRequestURL()
      if (to) {
        url.pathname = to.path
      }

      // Create queryObject to pass the params
      let query = {}
      if (params) {
        query = getQueryObjectFromURLParams(params)
      }

      // Remove protocol from url
      const page = await $fetch<ConfAPIPagesResponse>(
        `/conf-api/v1/pages/${url.toString().split('://')[1]}`,
        {
          query,
        },
      )

      if (page?.pagetype === 'redirect') {
        return page.body
      }
      raw.value = page

      const pageTranslations = raw.value.translations

      if (pageTranslations) {
        l10n.setPageTranslations(
          pageTranslations,
          raw.value!.locale,
          raw.value!.language,
        )
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    load,
    update,
    raw,
    domain,
    domainInEnvironment,
    currencies,
    currency,
    pagetype,
    page,
    salesoffice,
    navigation,
    siteSettings,
    socialMedia,
    paymentForms,
    partnerships,
    siteCode,
    body,
    translations,
    languagePrefix,
    locale,
    language,
    domainsCountryCode,
    destination,
    sites,
    logoCaption,
    pageID,
    prefixReviewspage,
    header,
    footer,
    links,
    seoLinks,
    searchPrefix,
  }
})
