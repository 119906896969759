import { nanoid } from 'nanoid'

export const useIDsStore = defineStore('ids', () => {
  const user: Ref<string> = ref('')
  const session: Ref<string> = ref('')
  const cycle: Ref<string> = ref('')
  const client: Ref<string | undefined> = ref(undefined)

  function init() {
    let newUser = nanoid()
    let newSession = nanoid()
    const newCycle = nanoid()
    const userFromCookie = useCookie('User-ID')
    if (userFromCookie.value) {
      newUser = userFromCookie.value
    }
    const sessionFromCookie = useCookie('Session-ID')
    if (sessionFromCookie.value) {
      newSession = sessionFromCookie.value
    }
    useCookie('User-ID', {
      expires: monthsAdd(new Date(), 3),
      sameSite: 'none',
      secure: true,
      path: '/',
    }).value = newUser
    useCookie('Session-ID', {
      sameSite: 'none',
      secure: true,
      path: '/',
    }).value = newSession
    session.value = newSession
    user.value = newUser
    cycle.value = newCycle
  }

  return { user, session, cycle, client, init }
})
