export const useUserMobileMenuStore = defineStore('userMobileMenu', () => {
  const isOpen = ref(false)

  function close() {
    isOpen.value = false
  }

  function open() {
    isOpen.value = true
  }

  return { isOpen, close, open }
})
