<template>
  <div class="p-4 items-center flex flex-col text-center border-t">
    <img :src="image" alt="error symbol" class="mt-6 m-4" />

    <div class="mt-10 mb-10 max-w-[682px]">
      <div v-if="props.type === 'generic'" class="grid gap-6">
        <p class="leading-8 font-normal text-2xl">
          {{ $t(`${trPrefix}oops`) }}
        </p>

        <p class="leading-6">
          {{ $t(`${trPrefix}sorry`) }}
        </p>

        <p class="font-medium leading-6">
          {{ $t(`${trPrefix}thanks`) }}
        </p>

        <WebccButton
          class="w-fit mx-auto"
          size="md"
          outline
          variant="white"
          @click="handleError"
          >{{ $t(`${trPrefix}goToHome`) }}
        </WebccButton>
      </div>

      <div v-else class="grid gap-6">
        <WebccButton
          class="w-fit mx-auto"
          size="md"
          outline
          variant="white"
          @click="submit"
          >{{ $t(`${trPrefix}goToHome`) }}
        </WebccButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'errors.guestWorld.'

const props = defineProps<{
  type: 'reservations' | 'precheckins' | 'generic'
}>()

const image = computed(() => {
  return `${useRuntimeConfig().public.imagekitBase}/guestworld/assets/robot-defect.png`
})

async function submit() {
  await navigateTo({ path: `/${props.type}/` })
}

const handleError = async () => {
  clearError()
  await navigateTo({ path: '/' }, { external: true })
}
</script>
