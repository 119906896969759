/*
 * Spezifikation: https://wiki.hotelplan.com/display/TSICHDDWEBCC/07+-+Header
 */

export default {
  logoClick,
  sitenavigationClick,
  languageSwitch,
  currencySwitch,
  uspClosed,
  phoneClick,
  historyOpened,
  historyItemClick,
  headerItemClick,
}

/* helper to get pagetype from virtPath */
function pageType(page: string) {
  return (
    {
      start: 'homesite',
    }[page] || page
  )
}

/* 1: Logo */
function logoClick() {
  useTracking().send({
    event: 'event',
    category: 'Header',
    action: 'Logo',
    label: pageType(useConfdata().pagetype || ''),
    nonInteraction: false,
  })
}

/* 2: Owner, 3: Partner, 5: lso-pages, 8: newsletter */
function sitenavigationClick(
  id: 'partner' | 'lso' | 'topcountries' | 'inspirations' | 'homeowner',
) {
  const action = {
    partner: [
      'Partner',
      `${useConfdata()
        .domain.split('.')
        .pop()}+${useL10N().language.toLowerCase()}`,
    ],
    lso: ['lso-pages', 'not set'],
    topcountries: ['Request popular destinations', 'MainNavigation'],
    inspirations: ['Request travel ideas', 'MainNavigation'],
    homeowner: ['Request homeowner', 'MainNavigation'],
  }[id] || ['newsletter', 'not set']

  useTracking().send({
    event: 'event',
    category: 'SiteNavigation',
    action: action[0],
    label: action[1],
    nonInteraction: false,
  })
}

// 4: language
function languageSwitch(lang: string) {
  useTracking().send({
    event: 'event',
    category: 'Header',
    action: 'Language',
    label: lang.toLowerCase(),
    nonInteraction: false,
  })
}

/* 6: Faq  - not required */

/* 7: currency */
function currencySwitch(currencyCode: string) {
  useTracking().send({
    event: 'event',
    category: 'Header',
    action: 'Currency',
    label: currencyCode,
    nonInteraction: false,
  })
}

/* 9 usp - gibt es nicht mehr */
function uspClosed({
  uspIndex,
  millisecondsDisplayed,
}: {
  uspIndex: string
  millisecondsDisplayed: number
}) {
  const dispayTimeRoundedToHalfSeconds =
    Math.round((millisecondsDisplayed / 1000) * 2) / 2
  const pagetype = pageType(useConfdata().pagetype || '')

  useTracking().send({
    event: 'event',
    category: 'Header',
    action: 'USP Layer',
    label: `${uspIndex} + ${dispayTimeRoundedToHalfSeconds} + ${pagetype}`,
    nonInteraction: true,
  })
}

/* 10 : contact phone number */
function phoneClick() {
  useTracking().send({
    event: 'event',
    category: 'Header',
    action: 'Outbound',
    label: `Phone Header + ${pageType(useConfdata().pagetype || '')}`,
    nonInteraction: false,
  })
}

/* 11 : last visited menu opened */
function historyOpened() {
  useTracking().send({
    event: 'event',
    category: 'Header',
    action: 'Recent Object',
    label: `opened + ${pageType(useConfdata().pagetype || '')}`,
    nonInteraction: false,
  })
}

/* 12: click to accommodation in last visited menu */
function historyItemClick(accommodationID: string) {
  useTracking().send({
    event: 'event',
    category: 'Header',
    action: 'Recent Object',
    label: `clicked + ${accommodationID}`,
    nonInteraction: false,
  })
}

/* 13: Header elements homepage link, favourites, contact us */

function headerItemClick(action: 'homepage' | 'favourites' | 'contactus') {
  const items = {
    homepage: {
      action: 'Request homepage',
      label: 'Back to homepage',
    },
    favourites: {
      action: 'Request favourites',
      label: 'My favourites',
    },
    contactus: {
      action: 'Request contact us',
      label: 'Contact us',
    },
  }
  useTracking().send({
    event: 'event',
    category: 'Header',
    action: items[action].action,
    label: items[action].label,
    nonInteraction: false,
  })
}
