<template>
  <div class="flex bg-white custom-toast gap-4 w-auto h-auto">
    <!-- Left section: Interchalet to Interhome icons -->
    <div class="flex items-start justify-start w-16 md:w-24 h-auto">
      <img
        src="assets/icons/logos/migration.svg"
        class="w-full h-auto flex items-start"
      />
    </div>

    <!-- Right section: Text content -->
    <div class="flex-1 flex-col gap-1">
      <!-- Title -->
      <h2 class="text-txt-stronger font-medium text-xs md:text-base">
        {{ $t(`${trPrefix}heading`) }}
      </h2>

      <!-- Description -->
      <p
        class="md:w-96 sm:w-48 text-txt-weak text-xs md:text-base my-2 flex flex-wrap"
      >
        {{ $t(`${trPrefix}description`) }}
      </p>

      <!-- Read More link -->
      <div class="flex gap-2" @click.prevent="handleReadMore">
        <h2 class="text-txt-stronger text-xs md:text-base">
          {{ $t(`${trPrefix}readMore`) }}
        </h2>

        <img src="assets/icons/site/chevron-right.svg" class="w-3 md:w-4" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const trPrefix = 'guestWorld.components.WebccInterchaletBrandMergeToaster.'

const { language } = useParams()
const requestPending = ref(false)

async function handleReadMore() {
  requestPending.value = true
  const url =
    !language || language === 'de'
      ? 'https://www.interhome.de/pages/aus-interchalet-wird-interhome'
      : 'https://www.interhome.com/pages/interchalet-becomes-interhome'
  await navigateTo(url, { external: true })
  requestPending.value = false
}
</script>
<style scoped>
.custom-toast {
  gap: 16px;
  border-radius: 8px;
}
</style>
