/*
 * Spezifikation: https://wiki.hotelplan.com/display/TSICHDDWEBCC/35+-+Reservation+management
 */

// Registrierung der Funktionen
export default {
  backButton,
  startProcess,
  cancelProcess,
  requestAuthcode,
  invalidAuthcode,
  correctAuthcode,
  confirmAction,
  processSuccessful,
  processFailed,
  choosePaymentMethod,
  promoCodeSuccess,
  promoCodeError,
  IBANsuccess,
  IBANerror,
}

/* helper */
function capitalize(exp: string) {
  return exp.charAt(0).toUpperCase() + exp.slice(1)
}

/* Zurück zur Übersicht */
function backButton(module: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Button click',
    label: 'Back to Booking',
    nonInteraction: false,
  })
}

/* Klick auf Buchen oder Änderung bestätigen */
function startProcess(module: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Button click',
    label: `Enter ${module} change`,
    nonInteraction: false,
  })
}

/* Prozess beim Schritt VerificationCode abbrechen */
function cancelProcess(module: string, step: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Button click',
    label: `Cancel ${module} process before ${step}`,
    nonInteraction: false,
  })
}

/* Authcode / Verification Code aktiv anfordern */
function requestAuthcode(module: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Button click',
    label: `Request new ${module} Authcode`,
    nonInteraction: false,
  })
}

/* ungültiger Verificationcode / invalid Authcode */
function invalidAuthcode(module: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Verification of authcode',
    label: `Wrong ${module} authcode`,
    nonInteraction: true,
  })
}

/* gültiger Verificationcode / valid Authcode */
function correctAuthcode(module: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Verification of Authcode',
    label: `Correct ${module} Authcode`,
    nonInteraction: true,
  })
}

/* Änderungswunsch endgültig bestätigen */
function confirmAction(module: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Button click',
    label: `Confirm ${module} process`,
    nonInteraction: false,
  })
}

/* Änderungsprozess erfolgreich abgeschlossen */
function processSuccessful(module: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Notification',
    label: `${capitalize(module)} process successful`,
    nonInteraction: true,
  })
}

/* cancellation or modify Process failed */
function processFailed(module: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Notification',
    label: `${capitalize(module)} process failed`,
    nonInteraction: true,
  })
}

/* choosePaymentMethod */
function choosePaymentMethod(module: string) {
  useTracking().send({
    event: 'event',
    category: `Reservations Module ${capitalize(module)}`,
    action: 'Form Tracking',
    label: `${capitalize(module)} Payment Method (change)`,
    nonInteraction: false,
  })
}

/* Gutschein-/PromotionCode erfolgreich angefordert */
function promoCodeSuccess() {
  useTracking().send({
    event: 'event',
    category: 'Reservations Module Refund',
    action: 'Request for PromotionCode',
    label: 'Request for PromotionCode successful',
    nonInteraction: true,
  })
}

/* kein Gutschein-/PromotionCode zurückgekommen */
function promoCodeError(error: string) {
  useTracking().send({
    event: 'event',
    category: 'Reservations Module Refund',
    action: 'Request for PromotionCode',
    label: `Request for PromotionCode failed - ${error}`,
    nonInteraction: true,
  })
}

/* valide IBAN erfolgreich gesendet */
function IBANsuccess() {
  useTracking().send({
    event: 'event',
    category: 'Reservations Module Refund',
    action: 'Send IBAN',
    label: 'IBAN successfully sent',
    nonInteraction: true,
  })
}

/* ungültige IBAN zurückgewiesen */
function IBANerror(error: string) {
  useTracking().send({
    event: 'event',
    category: 'Reservations Module Refund',
    action: 'Send IBAN',
    label: `Invalid IBAN sent - ${error}`,
    nonInteraction: true,
  })
}
