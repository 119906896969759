export default {
  dataLayerPageLoad,
  booking: bookingStepEvent,
  reservationsModule: resMgmtModule,
  precheckin: precheckinEvent,
}

// Kein Event! wird bei Initialisierung des plugins gefeuert und liefert initiale dataLayer-Daten für den page load
async function dataLayerPageLoad(eid: string, location: string) {
  const data = await PageLoadHandler()
  data.page.location = location

  // Set the pageType for the data object
  switch (useConfdata().pagetype) {
    case 'reservations':
      data.page.pageType = 'reservations-page'
      break
    case 'precheckin':
      if (!data.page.location.includes('/arrival')) {
        data.event = 'page_view'
      }
      data.page.pageType = 'precheckin-page'
      break
    case 'guestworld':
      data.event = 'page_view'
      // loop over the virt path and set the pageType
      switch (data.page.virtPath) {
        case '/login':
          data.page.pageType = 'login-page'
          break
        case '/register':
          data.page.pageType = 'register-page'
          break
        case '/forgotpassword':
          data.page.pageType = 'forgot-password-page'
          break
        case '/resetpassword':
          data.page.pageType = 'reset-password-page'
          break
        case '/verify':
          data.page.pageType = 'verify-page'
          break
        case '/myaccount':
        case '/myaccount/profile':
          data.page.pageType = 'myaccount-page'
          break
        case '/myaccount/bookings':
          data.page.pageType = 'mybookings-page'
          break
        default:
          data.page.pageType = 'unknown'
          break
      }
  }
  data.page.eid = eid
  data.booking = bookingBlock()
  if (usePartner().id) {
    data.partner = partnerBlock()
  }

  return data
}

async function bookingStepEvent(step: any, eid: string | undefined) {
  const data = await PageLoadHandler()
  data.page.eid = eid
  data.page.virtPath = `booking-${step}`
  data.booking = bookingBlock()
  if (usePartner().id) {
    data.partner = partnerBlock()
  }
  return data
}

async function resMgmtModule(module: any, eid: string | undefined) {
  const data = await PageLoadHandler()
  data.page.eid = eid
  data.page.virtPath = `/reservations/management/${module}`
  data.booking = bookingBlock()
  return data
}

async function precheckinEvent() {
  const data = await PageLoadHandler()
  data.event = 'page_view'
  data.page.eid = useRuntimeConfig().public.tracking.eid
  return data
}

// /* HELPER */

async function PageLoadHandler(): Promise<Pagedata> {
  const conf = useConfdata()
  const params = { ...useParams().redirect }
  const redirectParams = new URLSearchParams(
    params as Record<string, string>,
  ).toString()

  const { features } = useNuxtApp().$unleash

  const data = {
    user: await userBlock(),
    page: {
      eid:
        process.env.NODE_ENV === 'production' ? 'prod' : process.env.NODE_ENV,
      location:
        window.location.pathname + (redirectParams ? `?${redirectParams}` : ''),
      hrco: '200',
      virtPath: conf.page?.virtpath || 'unknown',
      pageArea: 'Portal',
      isIframe: !!useParams().iframe || false,
      pId: undefined,
      pageBreakpoint:
        window.innerWidth > 1024
          ? 'desktop'
          : window.innerWidth > 768
            ? 'tablet'
            : 'mobile',
      pagePool: 'interhome',
      pageCountry: conf.domainsCountryCode?.toLowerCase() || 'int',
      pageLanguage: useL10N().language || useParams().language || '',
      currency: useConfdata().currency || '',
      pageTypeCluster: 'guestworld',
      pageType: 'unknown',
    },
    features,
    event: '',
  }
  return data
}

function partnerBlock() {
  const partner = usePartner()
  if (partner.id && partner.extra) {
    return {
      id: partner.id,
      idZu: partner.extra,
      name: undefined,
    }
  }
  return { id: partner.id, name: undefined }
}

async function userBlock() {
  const loggedIn = await useAuth().isAuthenticated
  let accountID = ''
  if (loggedIn) {
    const { userData } = useUser()
    accountID = userData?.accountId || ''
  }

  return {
    isLoggedIn: loggedIn,
    userID: accountID?.toLocaleLowerCase(),
  }
}

function bookingBlock() {
  const conf = useConfdata()
  const { reservation } = useReservation()
  switch (conf.pagetype) {
    case 'reservations':
      return {
        checkin: reservation?.checkin,
        checkout: reservation?.checkout,
        duration: reservation?.duration,
        number: reservation?.number,
        token: reservation?.token,
        state: reservation?.state,
        partner_id: reservation?.partnerid,
        object_id: reservation?.accommodation,
      }
  }
}
