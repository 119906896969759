export function replacePlaceholders(text: string, tags: object): string {
  return Object.entries(tags).reduce(
    (result, [tag, value]) =>
      result.replace(new RegExp(`{${tag}}`, 'g'), value?.toString() ?? ''),
    text,
  )
}

export function pluralize(textResource: string, count = 0) {
  const choices = textResource.split('|')

  switch (choices.length) {
    case 1:
      return choices[0]
    case 2:
      return count > 1 ? choices[1] : choices[0]
    case 3:
    case 4:
      return count > 1 ? choices[2] : choices[count]
    default:
      console.error(
        `Invalid length of pluralization choices: ${choices.length}`,
      )
      return choices[0]
  }
}

export function seoify(input: string): string {
  return input.toLocaleLowerCase().replace(/[/ ]/g, '-')
}

export function capitalize(input: string): string {
  if (!input) return ''

  return input.charAt(0).toUpperCase() + input.slice(1)
}
