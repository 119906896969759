<template>
  <div class="flex min-h-screen flex-col text-txt flex-grow bg-bgr">
    <Colors :colors="colors" />
    <Header :logo-caption="logoCaption!" :phone="salesoffice?.phone" iserror />

    <div v-if="showDetails">
      <p>statusCode: {{ error?.statusCode }}</p>
      <p>statusMessage: {{ error?.statusMessage }}</p>
      <p>message: {{ error?.message }}</p>
      <p>cause: {{ error?.cause }}</p>
      <p>data: {{ error?.data }}</p>
      <p>fatal: {{ error?.fatal }}</p>
      <p>name: {{ error?.name }}</p>
      <p>stack: {{ error?.stack }}</p>
      <p>unhandled: {{ error?.unhandled }}</p>
    </div>

    <SomeError type="generic" />
    <Footer
      :footer-links="navigation?.links"
      class="print:hidden"
      :logo-caption="logoCaption"
    />
  </div>
</template>

<script setup lang="ts">
const { logoCaption, salesoffice, navigation } = useConfdata()
const showDetails = ref(process.env.NODE_ENV !== 'production')

const { colors } = useColors()

useHead({ title: 'Oops!' })

const error = useError()
</script>
