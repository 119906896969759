import pageload from './01-pageload'
import header from './02-header'
import reservations from './04-reservations'
import guestworld from './05-guestworld'
import booking from './06-booking'

const handlers = {
  pageload,
  header,
  reservations,
  guestworld,
  booking,
}

export default handlers

export type Handlers = typeof handlers
