const STORAGE_KEY = 'bookmarks'

export const useBookmarksStore = defineStore('bookmarks', () => {
  const raw = useLocalStorage<string[]>(STORAGE_KEY, [])
  const catalog: Ref<string[] | null> = ref(null)

  const count = computed(() => raw.value.length)

  const ids = reactive(new Set(raw.value))

  watch(ids, (collection) => {
    raw.value = [...collection]
  })

  function has(id: string) {
    return ids.has(id)
  }

  function toggle(id: string) {
    if (has(id)) {
      remove(id)
    } else {
      add(id)
    }
  }

  function add(id: string) {
    ids.add(id)
  }

  function remove(id: string) {
    ids.delete(id)
  }

  function clear() {
    ids.clear()
  }

  function append(codes: string[]) {
    codes.forEach((code) => ids.add(code))
  }

  async function createPersonalCatalog(
    params: Omit<
      PersonalCatalogCreateParams,
      'language' | 'salesoffice' | 'url' | 'parameters'
    >,
  ) {
    const partner = usePartner()
    const { language, salesoffice } = useConfdata()

    const body = {
      language: language!,
      salesoffice: salesoffice?.code,
      url: useRequestURL().toString(),
      ...params,
      parameters: Object.entries({
        PartnerId: partner.id || undefined,
        retailerMode: partner.id && partner.jwt ? 'T1' : '06',
      })
        .filter(([, value]) => value)
        .map(([key, value]) => `${key}=${value}`),
    }

    return await useFetch('/webcc-api/v1/forms/personalCatalog', {
      method: 'POST',
      body: toFormData(body),
    })
  }

  return {
    count,
    catalog,
    remove,
    add,
    clear,
    append,
    toggle,
    createPersonalCatalog,
  }
})
