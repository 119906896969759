export default defineNuxtRouteMiddleware((to) => {
  if (to.path.startsWith('/precheckins/iframepages')) {
    return
  }

  // Skip on the client
  if (import.meta.client) return

  // Init the ids
  useIDs().init()
})
