import { withQuery } from 'ufo'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // If the call is on the client side and we don't switch layouts, we don't need to call the conf-api
  if (import.meta.client && to.meta.layout === from.meta.layout) return
  // If the to path includes /liveness or /readiness, we don't want to call the conf-api
  if (to.path.includes('/liveness') || to.path.includes('/readiness')) return
  const url = useRequestURL()
  const conf = useConfdata()
  // Check for the language in the URL
  const language =
    (useParams().language as string) || useReservation().reservation?.language
  const urlParams = new URLSearchParams()
  if (language) {
    urlParams.append('language', language)
  }

  try {
    const now = new Date().toISOString()
    console.time(`confapi-${now}`)
    const redirect = (await conf.load(to, from, urlParams)) as
      | RedirectOut
      | undefined
    console.timeEnd(`confapi-${now}`)
    if (redirect) {
      console.log(
        '🧤 - defineNuxtRouteMiddleware confdata - redirect:',
        redirect,
      )
      return navigateTo(withQuery(redirect.location, { isRedirected: true }), {
        external: true,
        redirectCode: redirect.status || 301,
      })
    }
  } catch (e: any) {
    console.error('error in call to confapi:', url)
    console.error('error in call to confapi ->', e.message || e)
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('[Middleware] conf-api finished')
  }
})
