export default function useAccomm() {
  async function getAccommodation(
    accomCode: string,
    language: string,
    params: any,
  ) {
    try {
      return await $fetch<TranslatedAccommodation>(
        `/webcc-api/v1/accommodations/${accomCode}/${language}`,
        { params },
      )
    } catch (error) {
      console.info('Error while loading accommodation', error)
    }
  }

  return { getAccommodation }
}
