// import { error } from 'console'

export const useValidationStore = defineStore('validation', () => {
  async function email(email: any) {
    try {
      const res = await $fetch<EmailValidation>(
        `/validation-api/v1/emails/${email}`,
        { params: { hashprefix: 'ihcu' } },
      ).catch((error) => {
        console.error('error while validating email. ', error)
        throw error
      })
      return res
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    email,
  }
})
