export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path.startsWith('/precheckins/iframepages')) {
    return
  }

  // Skip if not on the precheckin layout
  if (to.meta.layout !== 'precheckin') return

  // Only execute this middleware if a token is present in the url and the layout has changed
  if (
    !to.params.token ||
    (import.meta.client && to.meta.layout === from.meta.layout)
  )
    return

  // Phase 1: Precheckin laden
  const precheckin = usePrecheckin()

  const token = to.params.token.toString()
  precheckin.token = token

  try {
    await precheckin.load()

    if (!precheckin.precheckin) {
      return
    }
  } catch (error) {
    console.error('Error loading precheckin data.', error)
    throw new Error('Error loading precheckin data.')
  }

  // If the path points to /precheckins/token/expired don't do a redirect
  if (to.path === `/precheckins/${token}/expired`) return

  // Otherwise get the links
  return precheckin.initLinks(to.path, to.query)
})
