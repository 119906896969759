export default {
  addBooking,
  addBookingSuccess,
  addBookingFailed,
  redirectedToSignin,
  addBookingSeccessAfterLogin,
  addBookingFailedAfterLogin,
}

function addBooking(partnerid = '') {
  useTracking().send({
    event: 'booking_linking',
    action: 'add',
    label: 'Add to Account',
    category: undefined,
    component: 'booking-linking',
    position: undefined,
    booking_source: partnerid,
    language: useConfdata().language,
    _clear: true,
  })
}

function addBookingSuccess(partnerid = '') {
  useTracking().send({
    event: 'booking_linking',
    action: 'success',
    label: 'New booking was added to your account',
    category: undefined,
    component: 'booking-linking',
    position: undefined,
    booking_source: partnerid,
    language: useConfdata().language,
    _clear: true,
  })
}

function addBookingFailed(partnerid = '') {
  useTracking().send({
    event: 'booking_linking',
    action: 'error',
    label: 'Not added, different email',
    category: undefined,
    component: 'booking-linking',
    position: undefined,
    booking_source: partnerid,
    language: useConfdata().language,
    _clear: true,
  })
}

function redirectedToSignin(partnerid = '') {
  useTracking().send({
    event: 'booking_linking',
    action: 'redirect',
    label: 'Redirect to Sign-In Page',
    category: undefined,
    component: 'booking-linking',
    position: undefined,
    booking_source: partnerid,
    language: useConfdata().language,
    _clear: true,
  })
}

function addBookingSeccessAfterLogin(partnerid = '') {
  useTracking().send({
    event: 'booking_linking',
    action: 'success',
    label: 'New booking was added to your account',
    category: 'redirected user',
    component: 'booking-linking',
    position: undefined,
    booking_source: partnerid,
    language: useConfdata().language,
    _clear: true,
  })
}

function addBookingFailedAfterLogin(partnerid = '') {
  useTracking().send({
    event: 'booking_linking',
    action: 'error',
    label: 'Not added, different email',
    category: 'redirected user',
    component: 'booking-linking',
    position: undefined,
    booking_source: partnerid,
    language: useConfdata().language,
    _clear: true,
  })
}
