<template>
  <a
    v-if="renderAsAnchor"
    :target="params.iframe ? '_self' : target"
    :href="url"
  >
    <slot />
  </a>
  <NuxtLink
    v-else
    :to="url"
    :target="params.iframe ? '_self' : target"
    :external="external"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
interface Props {
  absolute?: boolean
  anchor?: boolean
  target?: string
  to?: string | URL
  hash?: string
  external?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  absolute: false,
  anchor: false,
  target: '',
  to: '',
  hash: '',
  external: false,
})

const params = useParams()

const renderAsAnchor = computed(() => {
  const to = props.to.toString()
  return (
    props.anchor ||
    to.startsWith('http') ||
    to.startsWith('tel:') ||
    to.startsWith('mailto:') ||
    to.startsWith('//')
  )
})

const url = computed(() => {
  let path = props.to
  if (
    props.absolute ||
    typeof path === 'object' ||
    path.startsWith('http') ||
    path.startsWith('#') ||
    path.startsWith('tel:') ||
    path.startsWith('mailto:')
  ) {
    return path.toString()
  }
  // Allow self referential links but make sure to keep ssr-params
  if (path === '') {
    const searchParams = new URLSearchParams()
    Object.entries(params.persistent).forEach(([key, value]) => {
      searchParams.set(key, value.toString())
    })
    return searchParams.toString() ? `?${searchParams.toString()}` : ''
  }
  let search = ''
  if (path.includes('?')) {
    ;[path, search] = path.split('?')
  }
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  if (!path.endsWith('/')) {
    path = `${path}/`
  }
  // The second check is necessary since CMS-links in the conf-api response already include the language-prefix.
  if (
    useConfdata().languagePrefix &&
    !path.startsWith(`${useConfdata().languagePrefix}/`)
  ) {
    path = useConfdata().languagePrefix + path
  }
  const p = new URLSearchParams(search)
  Object.entries(params.persistent).forEach(([key, value]) => {
    p.set(key, value.toString())
  })
  // Check, if hash is set
  let hashParam = props.hash
  if (props.hash !== '') {
    // Check if # is set in the hash or not
    if (!props.hash.startsWith('#')) {
      hashParam = `#${hashParam}`
    }
  }
  return path + (p.toString() ? `?${p.toString()}` : '') + hashParam
})
</script>
