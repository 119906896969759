import omit from 'lodash-es/omit'
import omitBy from 'lodash-es/omitBy'
import pickBy from 'lodash-es/pickBy'
import isUndefined from 'lodash-es/isUndefined'
import { getQuery } from 'h3'

import type { LocationQuery } from '#vue-router'
import type { PlainParams, Params } from './params.types'

import { getQueryABParams } from './getters/ab-test-params'
import { getQueryCodeParams } from './getters/code-params'
import { getQueryEmailParams } from './getters/email-params'
import { getQueryFlowParams } from './getters/flow-params'
import { getQueryIFrameParams } from './getters/iframe-params'
import { getQueryLanguageParams } from './getters/language-params'
import { getQueryLinktoParams } from './getters/linkto-params'
import {
  getQueryPaymentSuccessParams,
  getQueryPaymentFailureParams,
} from './getters/payment-params'
import { getQueryReturntoParams } from './getters/returnto-params'

type ServerSideParams = PlainParams & { iframe?: IFrameParams }

export default defineNuxtPlugin({
  name: 'app:params',
  enforce: 'pre',
  dependsOn: [],
  setup(nuxtApp) {
    // Validated (external) GET parameters: are only evaluated server-side and not modified afterward
    const ssrParams = useState('ssr-params', () => {
      return import.meta.server
        ? getServerSideParams(getQuery(nuxtApp.ssrContext!.event))
        : {}
    })

    // Persisted params are outside of this module, e.g. PartnerID
    const persisted = reactive(new Map<string, unknown>())
    // const persistent: Ref<PlainParams> = ref({})
    const persistent = computed(
      () =>
        ({
          ...unpack(omit(ssrParams.value, 'redirect')),
          ...Object.fromEntries(persisted.entries()),
        }) as PlainParams,
    )

    const redirect: Ref<PlainParams> = ref({})

    // const iframe: Ref<IFrameParams | undefined> = ref()
    const iframe = computed(
      () => ssrParams.value.iframe && omit(ssrParams.value.iframe, 'iframe'),
    )

    const all: Ref<PlainParams> = ref({})
    const currency: Ref<string> = computed(() => {
      return all.value.currency?.toString() || 'EUR'
    })

    const language: Ref<string> = computed(() => {
      return all.value.language?.toString()
    })

    function init(params: Params) {
      redirect.value = unpack(params)
      // persistent.value = unpack(Object.fromEntries(Object.entries(params).filter(([key]) => key !== 'redirect')))
      // iframe.value = typeof params.iframe === 'object' ? ({ ...params.iframe } as IFrameParams) : undefined
      all.value = { ...persistent.value, ...unpack(params) }
    }

    // Creates a params string that can be set in the url from the all ref
    function createParamsString() {
      const params = { ...persistent.value, ...redirect.value }
      return Object.entries(params)
        .map(([key, value]) => {
          if (key !== 'token') {
            return ''
          }
          return `${key}=${value}`
        })
        .join('&')
    }

    // Gets the query parameters and a list of query keys that should be removed. These get removed from the query object and the useParams objects: persistent, redirect and all
    function removeQueryParams(query: LocationQuery, keysToRemove: string[]) {
      const queryCopy = { ...query }
      keysToRemove.forEach((key) => {
        if (queryCopy[key]) {
          delete queryCopy[key]
        }
      })

      // remove from the params from persistent, redirect and all if they exist
      keysToRemove.forEach((key) => {
        if (persistent.value[key]) {
          delete persistent.value[key]
        }

        if (redirect.value[key]) {
          delete redirect.value[key]
        }

        if (all.value[key]) {
          delete all.value[key]
        }
      })

      return queryCopy
    }

    const $params = reactive({
      persistent,
      redirect,
      language,
      iframe,
      all,
      currency,
      createParamsString,
      removeQueryParams,

      persist<V = unknown>(name: string, value: V) {
        persisted.set(name, value)
      },

      _init<Q extends Record<string, unknown>>(rawQuery: Q) {
        init(getUnifiedParams(rawQuery))
      },
    })

    nuxtApp.provide('params', $params)
  },
})

function getServerSideParams<Q extends Record<string, unknown>>(query: Q) {
  return omitBy(
    {
      ...getQueryABParams(query),
      ...getQueryCodeParams(query),
      ...getQueryEmailParams(query),
      ...getQueryFlowParams(query),
      ...getQueryIFrameParams(query),
      ...getQueryLanguageParams(query),
      ...getQueryLinktoParams(query),
      ...getQueryPaymentSuccessParams(query),
      ...getQueryPaymentFailureParams(query),
      ...getQueryReturntoParams(query),
    },
    isUndefined,
  ) as ServerSideParams
}

function getUnifiedParams<Q extends Record<string, unknown>>(rawQuery: Q) {
  const query = { ...rawQuery }

  const otherParams = Object.fromEntries(
    Object.entries(query)
      // .filter(([param]) => param in ALLOWED_QUERY_PARAMS && !(param in parsedParams))
      .map(([param, value]) => [
        param,
        value === 'true' ? true : value?.toString(),
      ]),
  )

  return pickBy({ ...otherParams }, Boolean) as Params
}
