export const useReviewsStore = defineStore('reviews', () => {
  const rating: Ref<ReviewsRating | undefined> = ref(undefined)
  const reviews: Ref<ReviewsSearchResultItem[]> = ref([])
  const reviewCount: Ref<number> = ref(0)
  const totalPages: Ref<number> = ref(0)

  async function translate(review: {
    title: string
    body: string
    tips: string
    targetlang: string
    reply: string
  }): Promise<ReviewTranslation | null> {
    try {
      const data = await $fetch<ReviewTranslation>(
        '/webcc-api/v1/translations',
        {
          method: 'POST',
          body: review,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      return data || null
    } catch (error) {
      console.log('Error while translating review', error)
      throw error
    }
  }

  async function loadReviews(
    page: number,
    {
      country,
      region,
      place,
    }: { country: string; region: string; place: string },
    locale: string,
    pagesize: number = 10,
    bestReviews: boolean = false,
  ) {
    const params = {
      ...parseURL(),
      page,
      locale,
      pagesize,
      bestReviews,
      ...(country ? { country } : {}),
      ...(region ? { region } : {}),
      ...(place ? { place } : {}),
    }

    try {
      const data = await $fetch<ReviewsSearchResult>('/webcc-api/v1/reviews', {
        params,
      })
      reviews.value = data.reviews || []
      rating.value = data.rating
      reviewCount.value = data._stats.totalHits || 0
      totalPages.value = Math.ceil(reviewCount.value / 10)
      // return data.value!.res
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  // TODO: add implementation for searchfilterconf in search middleware
  function parseURL() {
    const country = useConfdata().destination?.countryCode as string
    const region = useConfdata().destination?.regionCode as string
    const place = useConfdata().destination?.placeCode as string
    const query = {
      country,
      region,
      place,
      ...useParams().persistent,
    }
    const pathelements = useRequestURL()
      .pathname.split('/')
      .filter((p) => !!p)
    const searchfilterconf = (useConfdata().body as ReviewspageBody)
      .searchfilterconfs
    for (const conf of searchfilterconf || []) {
      if (pathelements.includes(conf.slug.path)) {
        const filtername = conf.filter
        if (['sea', 'lake', 'ski', 'center', 'pax'].includes(filtername)) {
          query[conf.filter] = conf.slug.value
        } else {
          query[conf.filter] = true
        }
      }
    }
    // falsy Werte löschen
    Object.keys(query).forEach((key) => !query[key] && delete query[key])
    console.log('parseURL query ', query)
    return query
  }

  return { rating, reviews, reviewCount, totalPages, loadReviews, translate }
})
