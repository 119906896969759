<template>
  <NuxtLayout>
    <NuxtPage />
    <Consent />
  </NuxtLayout>
  <Notifications />
</template>

<script lang="ts" setup>
const { bugherdApiKey } = useRuntimeConfig().public
const useHeadScripts = []

if (bugherdApiKey) {
  useHeadScripts.push({
    type: 'text/javascript',
    async: true,
    src: `https://www.bugherd.com/sidebarv2.js?apikey=${bugherdApiKey}`,
  })
}

useHead({
  script: useHeadScripts,
})
</script>

<style scoped></style>
