<template>
  <div />
</template>
<script setup lang="ts">
const showToasterEvt = useEventBus<ToasterDto>('showToaster')

onMounted(async () => {
  showToasterEvt.on(showToaster)
})

onUnmounted(() => {
  showToasterEvt.off(showToaster)
})

function showToaster(dto: ToasterDto) {
  const { type, message } = dto
  const toastFunction = useToaster().selectToasterFn(type)

  if (toastFunction) {
    nextTick(() => {
      toastFunction(message, { delay: 300 })
    })
  }
}
</script>
