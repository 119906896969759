import type { UseBreakpointsReturn } from '@vueuse/core'
import tailwindConfig from '@/tailwind.config'

type ScreenSize = keyof typeof tailwindConfig.theme.screens | undefined

export const useScreenStore = defineStore('screen', () => {
  const onResize: Ref<Function[]> = ref([])
  const breakpoints: ComputedRef<UseBreakpointsReturn> = computed(() => {
    return useBreakpoints(
      Object.fromEntries(
        Object.entries(tailwindConfig.theme.screens).map(([key, value]) => {
          return [key, parseInt((value as string).replace('px', ''))]
        }),
      ),
    ) as unknown as UseBreakpointsReturn // TODO: get rid of unknown and try to avoid using vueuse/core but use vueuse/nuxt instead
  })

  const current: Ref<ScreenSize> = computed(() => {
    return breakpoints.value.current().value.at(-1) as ScreenSize // the biggest current breakpoint
  })

  if (import.meta.client) {
    if (window.ResizeObserver) {
      let lastWidth: number | null = null
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect.width !== lastWidth) {
            lastWidth = entry.contentRect.width
            onResize.value.forEach((f) => f())
          }
        }
      })
      resizeObserver.observe(document.documentElement)
    }
  }

  function disableScrolling() {
    const cW = document.body.clientWidth
    document.documentElement.classList.add('!overflow-y-hidden')
    document.body.style.width = `${cW}px`
  }

  function enableScrolling() {
    document.documentElement.classList.remove('!overflow-y-hidden')
    document.body.style.width = ''
  }

  return { breakpoints, onResize, current, disableScrolling, enableScrolling }
})
