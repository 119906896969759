
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as callbackWxWLWZL2PgcHrVZucltDGvqbelHw0IMYJNvJLVE17VUMeta } from "/myapp/pages/callback.vue?macro=true";
import { default as forgotpasswordaKsOQ_45Lj3B2o2kRspjxXaa_45OtZ2b3bgO1v4fZ2KCFIcMeta } from "/myapp/pages/forgotpassword.vue?macro=true";
import { default as indexEWjern4t3BHeV9TRBaH98SdCKnJ2XYIatrQDxev173wMeta } from "/myapp/pages/index.vue?macro=true";
import { default as liveness7R3wbIOTUKW_45W3SugoWa5w6CANG35DovaQ7l_RaneSQMeta } from "/myapp/pages/liveness.vue?macro=true";
import { default as loginTWUvsQ5g_1nsL2NlztCXl4BFSMw1ArIbXZ8hLboffasMeta } from "/myapp/pages/login.vue?macro=true";
import { default as bookings4GnRCFjaOpwS1dXsFV5bpkRY89qGM2JUpO2sf6D48roMeta } from "/myapp/pages/myaccount/bookings.vue?macro=true";
import { default as indexp5sMb8beF4eIk_IOGYHrUqF1pVWtUP3gCSZ4Fy0yoSUMeta } from "/myapp/pages/myaccount/index.vue?macro=true";
import { default as profileIWxUXq0UtYuR_hILEyDBRTjODGvGGAv9l60oaOgyxScMeta } from "/myapp/pages/myaccount/profile.vue?macro=true";
import { default as _91token_93hSzDzFPIVQYsGvHsy_uNstiZNw_45vJeQ73PfpFG4UxJ4Meta } from "/myapp/pages/myaccount/wishlists/[token].vue?macro=true";
import { default as indexM41muRFllqy4ExmjVpSg2zQCmNhU5EHOGpAGa8y7UYcMeta } from "/myapp/pages/myaccount/wishlists/index.vue?macro=true";
import { default as arrivalg_45ZiuEBn5Uc7hoYD5sfLeQ8jZ7tM4omSHxZyRKP2vUAMeta } from "/myapp/pages/precheckins/[token]/arrival.vue?macro=true";
import { default as checkinZ1HHDZaesaaztTm2bG28TX5VqA_1Ftq022zZN5g969QMeta } from "/myapp/pages/precheckins/[token]/checkin.vue?macro=true";
import { default as customerVBp0rGVy8r10nR61r0DpEIRk0bkiE8_45oBycbRa4h_45QsMeta } from "/myapp/pages/precheckins/[token]/customer.vue?macro=true";
import { default as expiredgVUxJi_iiYh3oKYmfshndx2Acx9lz7xHRcPq3R_45rqLMMeta } from "/myapp/pages/precheckins/[token]/expired.vue?macro=true";
import { default as paxl090MaxzuuSbZOk3qvJ4Z9ri0NffTRcqZMigy0IMQ6MMeta } from "/myapp/pages/precheckins/[token]/pax.vue?macro=true";
import { default as servicesLfNXVUrIxqEXj5DfyD8nQgFrpLBuxoq8HnWfxz3NnAkMeta } from "/myapp/pages/precheckins/[token]/services.vue?macro=true";
import { default as travellersS5aL8seNHrOGl3xOOCGIaDSjXr_jneHoBv2jTO7wLx4Meta } from "/myapp/pages/precheckins/[token]/travellers.vue?macro=true";
import { default as _91token_93qxAk8pN5ER1IQjClF4DgkzTJC3mRbBnMjqvgTDkBSIIMeta } from "/myapp/pages/precheckins/[token].vue?macro=true";
import { default as failureaKzD09u_m4NSn3ZCSmeX4WVZOyHjG4cP4z0jSaNSErIMeta } from "/myapp/pages/precheckins/iframepages/failure.vue?macro=true";
import { default as successpendingKwp0vcnRZYbheRVma_45NsRt7KNC3t9d4yvqmz3H53rzUMeta } from "/myapp/pages/precheckins/iframepages/successpending.vue?macro=true";
import { default as indextnIwPNppoQM1gvK74WxfTDwKC5pcxLnQxlvVFJqhntoMeta } from "/myapp/pages/precheckins/index.vue?macro=true";
import { default as readiness7bHhwL06IkYip_45Ohh_WYgxqR7psr3urCzKUgbQave0MMeta } from "/myapp/pages/readiness.vue?macro=true";
import { default as registernHxkp_uR_wnCTq_4jG9_Zu5wZFgkL3rRwzCv0aXY_45hYMeta } from "/myapp/pages/register.vue?macro=true";
import { default as cancellationBekD5ra4wLbuPiOGhXDHwWvSUqgrRciRH_45c1P31KhfwMeta } from "/myapp/pages/reservations/[token]/cancellation.vue?macro=true";
import { default as indexgoJAt3n7w1yfvBJzj_ugiApsMbbgHY1d5royCU1DHD8Meta } from "/myapp/pages/reservations/[token]/index.vue?macro=true";
import { default as paxDWGolc5P0pRz5rCZ9HNgCJPglylq_8T8Zo5fGrrbM8EMeta } from "/myapp/pages/reservations/[token]/pax.vue?macro=true";
import { default as paymentnxfkbyyniXlw3H6XXYsoSJmHsxTVbWIAAfnpS8Uc7FsMeta } from "/myapp/pages/reservations/[token]/payment.vue?macro=true";
import { default as precheckin2Gg6aQ2QxeNv_K8bm9b92GRf70N9n7Zxjm9yVQmmsjAMeta } from "/myapp/pages/reservations/[token]/precheckin.vue?macro=true";
import { default as refund_45y_45MdfFJ_f4FKZ4tJYdkAXGZKCTLJm1Y9pioYpOSLUkMeta } from "/myapp/pages/reservations/[token]/refund.vue?macro=true";
import { default as reviewB82KIWQbzlzBtWXkYsrOTI_8k10zpXrZx2NUpgW9i2IMeta } from "/myapp/pages/reservations/[token]/review.vue?macro=true";
import { default as services6to2rWvVvhvK_qpRsI3HpUzTbHXlXUXCv0w0UNIkk1QMeta } from "/myapp/pages/reservations/[token]/services.vue?macro=true";
import { default as _91token_93QrXafPGRcU5j5mAnU3D6exajHC9LE2E_45KNp1ZFuXG1UMeta } from "/myapp/pages/reservations/[token].vue?macro=true";
import { default as indexKs2JpTnKB96Lguw2VVFlM02lnMDQdN0z11kCNZORfkAMeta } from "/myapp/pages/reservations/index.vue?macro=true";
import { default as resetpassworddg3BVJvm5Gu1iGty7jYgybFiO9pJpQ4_QKDyQQ4F0C8Meta } from "/myapp/pages/resetpassword.vue?macro=true";
import { default as verify3CsdRuiZXjvAoGaBXqgNB9etrWu8FXneSrpZLSi9fewMeta } from "/myapp/pages/verify.vue?macro=true";
export default [
  {
    name: "callback",
    path: "/callback",
    meta: callbackWxWLWZL2PgcHrVZucltDGvqbelHw0IMYJNvJLVE17VUMeta || {},
    component: () => import("/myapp/pages/callback.vue")
  },
  {
    name: "forgotpassword",
    path: "/forgotpassword",
    meta: forgotpasswordaKsOQ_45Lj3B2o2kRspjxXaa_45OtZ2b3bgO1v4fZ2KCFIcMeta || {},
    component: () => import("/myapp/pages/forgotpassword.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/myapp/pages/index.vue")
  },
  {
    name: "liveness",
    path: "/liveness",
    component: () => import("/myapp/pages/liveness.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginTWUvsQ5g_1nsL2NlztCXl4BFSMw1ArIbXZ8hLboffasMeta || {},
    component: () => import("/myapp/pages/login.vue")
  },
  {
    name: "myaccount-bookings",
    path: "/myaccount/bookings",
    meta: bookings4GnRCFjaOpwS1dXsFV5bpkRY89qGM2JUpO2sf6D48roMeta || {},
    component: () => import("/myapp/pages/myaccount/bookings.vue")
  },
  {
    name: "myaccount",
    path: "/myaccount",
    meta: indexp5sMb8beF4eIk_IOGYHrUqF1pVWtUP3gCSZ4Fy0yoSUMeta || {},
    component: () => import("/myapp/pages/myaccount/index.vue")
  },
  {
    name: "myaccount-profile",
    path: "/myaccount/profile",
    meta: profileIWxUXq0UtYuR_hILEyDBRTjODGvGGAv9l60oaOgyxScMeta || {},
    component: () => import("/myapp/pages/myaccount/profile.vue")
  },
  {
    name: "myaccount-wishlists-token",
    path: "/myaccount/wishlists/:token()",
    meta: _91token_93hSzDzFPIVQYsGvHsy_uNstiZNw_45vJeQ73PfpFG4UxJ4Meta || {},
    component: () => import("/myapp/pages/myaccount/wishlists/[token].vue")
  },
  {
    name: "myaccount-wishlists",
    path: "/myaccount/wishlists",
    meta: indexM41muRFllqy4ExmjVpSg2zQCmNhU5EHOGpAGa8y7UYcMeta || {},
    component: () => import("/myapp/pages/myaccount/wishlists/index.vue")
  },
  {
    name: "precheckins-token",
    path: "/precheckins/:token()",
    meta: _91token_93qxAk8pN5ER1IQjClF4DgkzTJC3mRbBnMjqvgTDkBSIIMeta || {},
    component: () => import("/myapp/pages/precheckins/[token].vue"),
    children: [
  {
    name: "precheckins-token-arrival",
    path: "arrival",
    component: () => import("/myapp/pages/precheckins/[token]/arrival.vue")
  },
  {
    name: "precheckins-token-checkin",
    path: "checkin",
    component: () => import("/myapp/pages/precheckins/[token]/checkin.vue")
  },
  {
    name: "precheckins-token-customer",
    path: "customer",
    component: () => import("/myapp/pages/precheckins/[token]/customer.vue")
  },
  {
    name: "precheckins-token-expired",
    path: "expired",
    component: () => import("/myapp/pages/precheckins/[token]/expired.vue")
  },
  {
    name: "precheckins-token-pax",
    path: "pax",
    component: () => import("/myapp/pages/precheckins/[token]/pax.vue")
  },
  {
    name: "precheckins-token-services",
    path: "services",
    component: () => import("/myapp/pages/precheckins/[token]/services.vue")
  },
  {
    name: "precheckins-token-travellers",
    path: "travellers",
    component: () => import("/myapp/pages/precheckins/[token]/travellers.vue")
  }
]
  },
  {
    name: "precheckins-iframepages-failure",
    path: "/precheckins/iframepages/failure",
    component: () => import("/myapp/pages/precheckins/iframepages/failure.vue")
  },
  {
    name: "precheckins-iframepages-successpending",
    path: "/precheckins/iframepages/successpending",
    component: () => import("/myapp/pages/precheckins/iframepages/successpending.vue")
  },
  {
    name: "precheckins",
    path: "/precheckins",
    component: () => import("/myapp/pages/precheckins/index.vue")
  },
  {
    name: "readiness",
    path: "/readiness",
    component: () => import("/myapp/pages/readiness.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registernHxkp_uR_wnCTq_4jG9_Zu5wZFgkL3rRwzCv0aXY_45hYMeta || {},
    component: () => import("/myapp/pages/register.vue")
  },
  {
    name: _91token_93QrXafPGRcU5j5mAnU3D6exajHC9LE2E_45KNp1ZFuXG1UMeta?.name,
    path: "/reservations/:token()",
    meta: _91token_93QrXafPGRcU5j5mAnU3D6exajHC9LE2E_45KNp1ZFuXG1UMeta || {},
    component: () => import("/myapp/pages/reservations/[token].vue"),
    children: [
  {
    name: "reservations-token-cancellation",
    path: "cancellation",
    component: () => import("/myapp/pages/reservations/[token]/cancellation.vue")
  },
  {
    name: "reservations-token",
    path: "",
    component: () => import("/myapp/pages/reservations/[token]/index.vue")
  },
  {
    name: "reservations-token-pax",
    path: "pax",
    component: () => import("/myapp/pages/reservations/[token]/pax.vue")
  },
  {
    name: "reservations-token-payment",
    path: "payment",
    component: () => import("/myapp/pages/reservations/[token]/payment.vue")
  },
  {
    name: "reservations-token-precheckin",
    path: "precheckin",
    component: () => import("/myapp/pages/reservations/[token]/precheckin.vue")
  },
  {
    name: "reservations-token-refund",
    path: "refund",
    component: () => import("/myapp/pages/reservations/[token]/refund.vue")
  },
  {
    name: "reservations-token-review",
    path: "review",
    component: () => import("/myapp/pages/reservations/[token]/review.vue")
  },
  {
    name: "reservations-token-services",
    path: "services",
    component: () => import("/myapp/pages/reservations/[token]/services.vue")
  }
]
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/myapp/pages/reservations/index.vue")
  },
  {
    name: "resetpassword",
    path: "/resetpassword",
    meta: resetpassworddg3BVJvm5Gu1iGty7jYgybFiO9pJpQ4_QKDyQQ4F0C8Meta || {},
    component: () => import("/myapp/pages/resetpassword.vue")
  },
  {
    name: "verify",
    path: "/verify",
    meta: verify3CsdRuiZXjvAoGaBXqgNB9etrWu8FXneSrpZLSi9fewMeta || {},
    component: () => import("/myapp/pages/verify.vue")
  }
]