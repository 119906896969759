import handlers from '~/resources/tracking'

export default defineNuxtRouteMiddleware((to) => {
  if (to.path.startsWith('/precheckins/iframepages')) {
    return
  }

  // Skip on the client
  if (import.meta.server) return

  window.dataLayer = window.dataLayer || []
  // No need to wait for the tracking. This can be done in the background
  useTracking().init(handlers, handlers.pageload.dataLayerPageLoad, to.path)
})
