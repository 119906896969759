<template>
  <header class="bg-bgr" :class="{ bordershadow: iserror }">
    <div
      class="container-fluid-lg flex justify-between py-4 lg:container print:max-w-sm"
    >
      <WebccCompanyLogo :caption="logoCaption" :href="logoUrl" external />
      <div
        v-if="!myrent && links?.contact?.href"
        class="flex-end flex items-center print:hidden"
      >
        <span class="pointer-events-auto">
          {{ $t(`${trPrefix}hereForYou`) }}
        </span>

        <WebccLink
          class="text-sm sm:text-lg cursor-pointer pointer-events-auto ml-3 underline underline-offset-1"
          external
          target="blank"
          :to="links.contact.href"
          @click="tracking.handlers?.header.headerItemClick('contactus')"
        >
          {{ $t(`${trPrefix}contactUs`) }}
        </WebccLink>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
const trPrefix = 'guestWorld.header.'
const tracking = useTracking()

interface Props {
  logoUrl?: string
  phone?: string
  myrent?: boolean
  iserror?: boolean
}

withDefaults(defineProps<Props>(), {
  logoUrl: '/',
  phone: '',
  myrent: false,
  iserror: false,
})

const { links, logoCaption } = storeToRefs(useConfdata())
</script>

<style scoped>
.bordershadow {
  box-shadow: 0px 2px 3px 0px #1515151a;
}
</style>
