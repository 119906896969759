import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/myapp/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/myapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/myapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/myapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import params_plugin_hHjMBtLDWEP8tHsZXsGqkwcuahGBEh25r6sXv9HLgCE from "/myapp/modules/params/params.plugin.ts";
import i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c from "/myapp/plugins/i18n.ts";
import stores_oZV7vqNzyMdACYeyCj6KdGm0goJRh4n4Bcp6gSkECLs from "/myapp/plugins/stores.ts";
import vue_query_GNtWx11mSO94O_Ctd2OG8SaawZMBzi1ne17Edj_iIOc from "/myapp/plugins/vue-query.ts";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/myapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/myapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/myapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/myapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/myapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/myapp/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/myapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unleash_plugin_client_rJbSQENbkxmGGxKuwgNEyP5EJY_FvSF8S_t1g3ar55M from "/myapp/node_modules/webcc-unleash/dist/runtime/unleash.plugin.client.js";
import plugin_client_w8gOGEJbCd_zll3tePMHpSlPcNH1LmVhU_SfzQ0BgV4 from "/myapp/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ from "/myapp/.nuxt/floating-vue.mjs";
import plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68 from "/myapp/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import colors_WcTfNdsymmanCvJ3ZA6T4NJsGmWPlF496hQgWwVj8ZA from "/myapp/plugins/colors.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/myapp/plugins/sentry.client.ts";
import vue3_toastify_client_7NzB2SKUE19ktZgM2Q43E2Ej3jIAlOtZD5OOsRycyVo from "/myapp/plugins/vue3-toastify.client.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  params_plugin_hHjMBtLDWEP8tHsZXsGqkwcuahGBEh25r6sXv9HLgCE,
  i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c,
  stores_oZV7vqNzyMdACYeyCj6KdGm0goJRh4n4Bcp6gSkECLs,
  vue_query_GNtWx11mSO94O_Ctd2OG8SaawZMBzi1ne17Edj_iIOc,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  unleash_plugin_client_rJbSQENbkxmGGxKuwgNEyP5EJY_FvSF8S_t1g3ar55M,
  plugin_client_w8gOGEJbCd_zll3tePMHpSlPcNH1LmVhU_SfzQ0BgV4,
  floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ,
  plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68,
  colors_WcTfNdsymmanCvJ3ZA6T4NJsGmWPlF496hQgWwVj8ZA,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  vue3_toastify_client_7NzB2SKUE19ktZgM2Q43E2Ej3jIAlOtZD5OOsRycyVo
]