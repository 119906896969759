import type { Handlers } from '~/resources/tracking'

export const useTrackingStore = defineStore('tracking', () => {
  const options: Ref<{
    handlers?: Record<string, Function>
    disabled?: boolean
    gtmID?: string
  }> = ref({})
  const handlers: Ref<Handlers | null> = ref(null)
  const cid = ref('')

  function trackConsent(consent: Consent, event = true) {
    if (event) {
      window.dataLayer.push({
        event: 'user consent',
        consentStatistics: consent.statistics,
        consentRetargeting: consent.other,
      })
    } else {
      window.dataLayer.push({
        consentStatistics: consent.statistics,
        consentRetargeting: consent.other,
      })
    }
  }

  async function init(
    h: Handlers,
    pageloadHandler: Function,
    location: string,
  ) {
    if (import.meta.server) {
      return
    }
    const config = useRuntimeConfig()
    const consent: Ref<Consent> = useLocalStorage(CONSENTSTORAGEKEY, {})
    window.dataLayer = window.dataLayer || []

    handlers.value = h

    options.value = { ...config.public.tracking, h }

    window.dataLayer.push(
      await pageloadHandler(config.public.tracking.eid, location),
    )

    if (consent.value) {
      const initialConsent = consent.value
      if (initialConsent) {
        trackConsent(initialConsent, false)
      }
    }

    if (
      !options.value.disabled &&
      useParams().all.tracking !== false &&
      options.value.gtmID
    ) {
      loadGTM(options.value.gtmID)
    }

    cid.value = useCookies()._ga?.split('.').slice(-2).join('.') || ''
  }

  function send(element: TrackingElement | TrackingElement[]) {
    if (!Array.isArray(element)) {
      element = [element]
    }
    element.forEach((e) => {
      if (window.dataLayer && !options.value.disabled) {
        // console.log('tracking event for debugging: ', e)

        window.dataLayer.push(e)
      }
    })
  }

  return {
    handlers,
    cid,
    trackConsent,
    init,
    send,
  }
})

function loadGTM(gtmID: string) {
  window.dataLayer.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
    eventCallback(id: string) {
      if (id === gtmID && window.gaGlobal) {
        // Only set client id in projects that need it
        if (useIDs().client !== undefined) {
          useIDs().client = window.gaGlobal.vid
        }
      }
    },
  })

  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmID}`
  document.body.appendChild(script)
}
