<template>
  <div class="flex flex-col bg-white w-auto h-auto">
    <span v-if="props.isSuccess" class="font-base text-xs md:text-base">
      {{ successText }}
    </span>

    <span
      v-if="props.isSuccess && props.withLink"
      class="font-base text-xs md:text-base underline cursor-pointer"
      @click="goToMyBookings"
    >
      {{ linkText }}
    </span>

    <span v-if="!props.isSuccess" class="font-base text-xs md:text-base">
      {{ errorText }}
    </span>
  </div>
</template>
<script lang="ts" setup>
const trPrefix = 'guestWorld.components.AddToAccountToaster.'

const { t } = useI18n()

const props = defineProps<{
  isSuccess: boolean
  withLink: boolean
  errorTemplate?: string | null | undefined
}>()

async function goToMyBookings() {
  await navigateTo('/myaccount/bookings', { external: true })
}

const successText = computed(() => {
  if (!props.isSuccess) {
    return ''
  }
  return t(`${trPrefix}success` as TranslationKey)
})

const linkText = computed(() => {
  if (!props.isSuccess || !props.withLink) {
    return ''
  }
  return t(`${trPrefix}link` as TranslationKey)
})

const errorText = computed(() => {
  if (!props.errorTemplate) {
    return ''
  }
  return t(`${trPrefix}${props.errorTemplate}` as TranslationKey)
})
</script>
