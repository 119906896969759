<template>
  <!-- Mobile -->
  <WebccModal
    v-if="sm"
    :open="bannerOpen && !configOpen"
    :title="$t(`${trPrefix}cookiesTitle`)"
    :close-button="false"
  >
    <template #default>
      <span>{{ $t(`${trPrefix}cookiesInfotextBefore`) }}</span
      >&nbsp;<span
        class="inline-block cursor-pointer font-medium underline hover:no-underline"
        @click="$emit('openConfig')"
        >{{ $t(`${trPrefix}cookiesConfigureHere`) }}</span
      >

      <span>{{ $t(`${trPrefix}cookiesInfotextAfter`) }}</span
      >&nbsp;<span>{{ $t(`${trPrefix}cookiesMoreInfoBefore`) }}</span
      >&nbsp;<span
        class="inline-block cursor-pointer font-medium underline hover:no-underline"
        @click="openPrivacypolicy()"
        >{{ $t(`${trPrefix}cookiesMoreInfoHere`) }}</span
      >&nbsp;<span>{{ $t(`${trPrefix}cookiesMoreInfoAfter`) }}</span>
    </template>

    <template #footer>
      <div class="flex flex-col items-center justify-start gap-4">
        <WebccButton
          class="w-full"
          size="lg"
          variant="theme"
          @click="$emit('acceptAll')"
        >
          {{ $t(`${trPrefix}cookiesAccept`) }}
        </WebccButton>
        <span
          class="cursor-pointer underline transition-all hover:no-underline"
          @click="$emit('rejectAll')"
          >{{ $t(`${trPrefix}cookiesDecline`) }}</span
        >
        <span
          class="cursor-pointer underline transition-all hover:no-underline"
          @click="() => $emit('openConfig')"
        >
          {{ $t(`${trPrefix}cookiesConfigureLink`) }}
        </span>
      </div>
    </template>
  </WebccModal>
  <!-- Desktop -->
  <div
    v-else-if="bannerOpen"
    class="fixed flex w-full bottom-9 justify-center px-6"
  >
    <div
      class="consent-banner container-fluid-lg my-8 rounded bg-bgr p-6"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <p class="mb-4 font-medium text-lg sm:text-xl">
        {{ $t(`${trPrefix}cookiesTitle`) }}
      </p>

      <span>{{ $t(`${trPrefix}cookiesInfotextBefore`) }}</span
      >&nbsp;<span
        class="inline-block cursor-pointer font-medium underline hover:no-underline"
        @click="$emit('openConfig')"
        >{{ $t(`${trPrefix}cookiesConfigureHere`) }}</span
      >

      <span>{{ $t(`${trPrefix}cookiesInfotextAfter`) }}</span
      >&nbsp;<span>{{ $t(`${trPrefix}cookiesMoreInfoBefore`) }}</span
      >&nbsp;<span
        class="inline-block cursor-pointer font-medium underline hover:no-underline"
        @click="openPrivacypolicy()"
        >{{ $t(`${trPrefix}cookiesMoreInfoHere`) }}</span
      >&nbsp;<span>{{ $t(`${trPrefix}cookiesMoreInfoAfter`) }}</span>

      <div
        class="mt-6 flex flex-col items-center justify-start gap-4 sm:flex-row"
      >
        <WebccButton
          class="h-12 w-48"
          variant="theme"
          @click="$emit('acceptAll')"
        >
          {{ $t(`${trPrefix}cookiesAccept`) }}
        </WebccButton>
        <span
          class="cursor-pointer underline transition-all hover:no-underline"
          @click="$emit('rejectAll')"
          >{{ $t(`${trPrefix}cookiesDecline`) }}
        </span>
        <span
          class="cursor-pointer underline transition-all hover:no-underline"
          @click="() => $emit('openConfig')"
        >
          {{ $t(`${trPrefix}cookiesConfigureLink`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'components.cookieConsent.'

defineProps<{ bannerOpen: boolean; configOpen: boolean }>()
defineEmits(['openConfig', 'acceptAll', 'rejectAll'])

const sm = useScreenStore().breakpoints.smallerOrEqual('sm')

const pplink = computed(() => {
  return (
    useConfdata().navigation?.links.privacyPolicy?.href ||
    'https://www.interhome.group/privacy-policy'
  )
})

function openPrivacypolicy() {
  return navigateTo(pplink.value, { external: true })
}
</script>

<style scoped>
.consent-banner {
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.12);
}
</style>
