<template>
  <ClientOnly>
    <Teleport v-if="useStorageReady()" to="body">
      <ConsentConfig
        v-model:other="choice.other"
        v-model:statistics="choice.statistics"
        :config-open="configOpen"
        @accept-all="acceptAll"
        @save-choice="saveChoice"
        @close-config="closeConfig"
      />
      <ConsentBanner
        :banner-open="bannerOpen"
        :config-open="configOpen"
        @accept-all="acceptAll"
        @reject-all="rejectAll"
        @open-config="openConfig"
      />
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
const consent: Ref<Consent> = useLocalStorage(CONSENTSTORAGEKEY, {})
const configOpen = useState('consentConfigOpen', () => false)

const bannerOpen = computed(() => {
  return !consent.value.timestamp
})

function openConfig() {
  configOpen.value = true
}

function closeConfig() {
  configOpen.value = false
}

onMounted(() => {
  if (
    consent.value &&
    consent.value.statistics !== undefined &&
    consent.value.other !== undefined
  ) {
    choice.value.statistics = consent.value.statistics
    choice.value.other = consent.value.other
  }
})

const choice = ref<Choice>({
  statistics: false,
  other: false,
})

function acceptAll() {
  choice.value.statistics = true
  choice.value.other = true
  saveChoice()
}

function rejectAll() {
  choice.value.statistics = false
  choice.value.other = false
  saveChoice()
}

function saveChoice() {
  update({
    timestamp: new Date(),
    statistics: choice.value.statistics,
    other: choice.value.other,
    initDecision: consent.value.initDecision === null,
  })
  closeConfig()
}

function update(value: Consent) {
  consent.value = value
  // @ts-expect-ignore
  if (useTracking) {
    useTracking().trackConsent(value)
  }
  if ((window as any)?.gtag) {
    ;(window as any).gtag('consent', 'update', {
      ad_user_data: value.other ? 'granted' : 'denied', // maps to Marketing Cookies
      ad_personalization: value.other ? 'granted' : 'denied', // maps to Marketing Cookies
      ad_storage: value.other ? 'granted' : 'denied', // maps to Marketing Cookies
      analytics_storage: value.statistics ? 'granted' : 'denied', // maps to Analytics Cookies
    })

    if (!value.statistics) {
      ;(window as any).gtag('set', 'url_passthrough', true)
    }
  }
}
</script>
