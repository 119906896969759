<template>
  <WebccModal
    :open="configOpen"
    class="flex w-[95%] max-w-2xl flex-col"
    :title="$t(`${trPrefix}cookiesManagePreferences`)"
    @close="$emit('closeConfig')"
  >
    <section class="flex flex-col gap-4 bg-bgr-100 p-5 -m-5">
      <div class="grid grid-cols-[1fr_auto] gap-1">
        <p class="text-base font-bold text-txt">
          {{ $t(`${trPrefix}cookiesFunctionalTitle`) }}
        </p>

        <WebccSwitch :model-value="true" disabled />

        <p class="col-span-2 text-sm text-txt md:text-base">
          {{ $t(`${trPrefix}cookiesFunctionalText`) }}
        </p>
      </div>

      <div class="grid grid-cols-[1fr_auto] gap-1">
        <p class="text-base font-bold text-txt">
          {{ $t(`${trPrefix}cookiesAnalyticalTitle`) }}
        </p>

        <WebccSwitch v-model="statistics" />

        <p class="col-span-2 text-sm text-txt md:text-base">
          {{ $t(`${trPrefix}cookiesAnalyticalText`) }}
        </p>
      </div>

      <div class="grid grid-cols-[1fr_auto] gap-1">
        <p class="text-base font-bold text-txt">
          {{ $t(`${trPrefix}cookiesMarketingTitle`) }}
        </p>

        <WebccSwitch v-model="other" />

        <p class="col-span-2 text-sm text-txt md:text-base">
          {{ $t(`${trPrefix}cookiesMarketingText`) }}
        </p>
      </div>
    </section>

    <template #footer>
      <div
        class="flex shrink-0 flex-col-reverse gap-4 sm:flex-row sm:justify-end"
      >
        <WebccButton size="lg" @click="$emit('saveChoice')">
          {{ $t(`${trPrefix}cookiesConfirmChoice`) }}
        </WebccButton>
        <WebccButton size="lg" variant="theme" @click="$emit('acceptAll')">
          {{ $t(`${trPrefix}cookiesAcceptAll`) }}
        </WebccButton>
      </div>
    </template>
  </WebccModal>
</template>

<script setup lang="ts">
const trPrefix = 'components.cookieConsent.'

defineProps<{
  configOpen: boolean
}>()

defineEmits(['closeConfig', 'acceptAll', 'saveChoice'])

const statistics = defineModel<boolean>('statistics', { required: true })
const other = defineModel<boolean>('other', { required: true })
</script>

<style scoped></style>
