import validate from "/myapp/node_modules/nuxt/dist/pages/runtime/validate.js";
import _02_45params_45global from "/myapp/middleware/02.params.global.ts";
import _03_45ids_45global from "/myapp/middleware/03.ids.global.ts";
import _04_45res_45data_45global from "/myapp/middleware/04.res-data.global.ts";
import _05_45conf_45data_45global from "/myapp/middleware/05.conf-data.global.ts";
import _06_45pci_45data_45global from "/myapp/middleware/06.pci-data.global.ts";
import _07_45auth_45global from "/myapp/middleware/07.auth.global.ts";
import _09_45tracking_45global from "/myapp/middleware/09.tracking.global.ts";
import manifest_45route_45rule from "/myapp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _02_45params_45global,
  _03_45ids_45global,
  _04_45res_45data_45global,
  _05_45conf_45data_45global,
  _06_45pci_45data_45global,
  _07_45auth_45global,
  _09_45tracking_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}