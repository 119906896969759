export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path.startsWith('/precheckins/iframepages')) {
    return
  }

  // Skip if not on the reservations layout
  if (to.meta.layout !== 'reservations') return

  // Only execute this middleware if a token is present in the url and the layout has changed
  if (
    !to.params.token ||
    (import.meta.client && to.meta.layout === from.meta.layout)
  )
    return

  const reservation = useReservation()
  // Phase 1: Reservierung laden
  try {
    await reservation.load(to.params.token.toString(), true)
    if (!reservation.reservation) {
      throw new Error('Reservation not found')
    }
  } catch (error) {
    console.error('Error while loading the reservation', error)
  }
})
