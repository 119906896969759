import type { LocationQuery } from '#vue-router'
import { nanoid } from 'nanoid'

const stepsDefinition: Record<PCIMode, Step[]> = {
  all: [
    {
      id: 'customer',
      path: 'customer',
    },
    {
      id: 'arrival',
      path: 'arrival',
    },
    {
      id: 'pax',
      path: 'pax',
    },
    {
      id: 'travellers',
      path: 'travellers',
    },
    {
      id: 'services',
      path: 'services',
    },
    {
      id: 'checkin',
      path: '',
    },
  ],
  default: [
    {
      id: 'arrival',
      path: 'arrival',
    },
    {
      id: 'pax',
      path: 'pax',
    },
    {
      id: 'travellers',
      path: 'travellers',
    },
    {
      id: 'services',
      path: 'services',
    },
    {
      id: 'checkin',
      path: '',
    },
  ],
  easy: [
    {
      id: 'arrival',
      path: 'arrival',
    },
    {
      id: 'travellers',
      path: 'travellers',
    },
    {
      id: 'checkin',
      path: '',
    },
  ],
  zero: [
    {
      id: 'customer',
      path: 'customer',
    },
  ],
}

export const usePrecheckinStore = defineStore('precheckin', () => {
  const token = ref('')
  const currentPath = ref('')
  const precheckin: Ref<Precheckin | undefined> = ref()
  const accommodation: Ref<TranslatedAccommodation | undefined> = ref()
  const pax: Ref<Record<string, PaxType[]>> = ref({
    babies: [],
    children: [],
    teenager: [],
    adults: [],
    pensioner: [],
  })
  const travellers: Ref<V5PrecheckinTraveler[]> = ref([])
  const layouts: Ref<Record<string, PrecheckinField[]> | undefined> = ref()
  const travellersListed = ref(false)
  const requestEmail = ref(false)

  const uReservation = useReservation()

  const reservation = computed(() => {
    return precheckin.value?.reservation
  })

  const customer = computed(() => {
    return precheckin.value?.customer
  })

  const contact = computed(() => {
    return precheckin.value?.contact
  })

  const mode = computed(() => {
    return precheckin.value?.mode
  })

  const steps: Ref<Step[]> = ref([])
  const step: Ref<
    'zero' | 'arrival' | 'services' | 'travellers' | 'checkin' | 'pax' | ''
  > = ref('')
  const links: Ref<{ prev?: string; next?: string }> = ref({})

  const modes: Ref<Record<string, string[]>> = ref({
    all: [''],
    default: [''],
    easy: [''],
    zero: [''],
  })

  const computedLinks = computed(() => {
    const config = useRuntimeConfig()
    const ALL_MODE = config.public.allMode // used for debugging purposes mostly. Default is false

    // Fetch the mode
    const mode = (ALL_MODE ? 'all' : precheckin.value?.mode) || 'default'

    const modeIndex = modes.value[mode].indexOf(currentPath.value)

    return {
      prev: modes.value[mode][modeIndex - 1] || undefined,
      next: modes.value[mode][modeIndex + 1] || undefined,
    }
  })

  function initPax(paxValues: V5PrecheckinPax, paxTypes: PaxType[]) {
    pax.value = {
      babies: [],
      children: [],
      teenager: [],
      adults: [],
      pensioner: [],
    }
    let babiesSet = false
    let childrenSet = false
    let adultsSet = false
    paxTypes.forEach((type) => {
      switch (type.category) {
        case 'babies':
          pax.value.babies.push({
            category: 'babies',
            from: type.from || 0,
            to: type.to || 0,
            value: !babiesSet ? paxValues.babies : 0,
          })
          babiesSet = true
          break
        case 'children':
          pax.value.children.push({
            category: 'children',
            from: type.from || 0,
            to: type.to || 0,
            value: !childrenSet ? paxValues.children : 0,
          })
          childrenSet = true
          break
        case 'teenager':
          pax.value.teenager.push({
            category: 'teenager',
            from: type.from || 0,
            to: type.to || 0,
            value: 0,
          })
          break
        case 'adults':
          pax.value.adults.push({
            category: 'adults',
            from: type.from || 0,
            to: type.to || 0,
            value: !adultsSet ? paxValues.adults : 0,
          })
          adultsSet = true
          break
        case 'pensioner':
          pax.value.pensioner.push({
            category: 'pensioner',
            from: type.from || 0,
            to: type.to || 0,
            value: 0,
          })
          break

        default:
          break
      }
    })
    Object.values(pax.value).forEach((category) => {
      category.sort((e1, e2) => {
        if (e1.from && e2.from) {
          return e1.from < e2.from ? 1 : -1
        } else if (e1.to && e2.to) {
          return e1.from > e2.from ? 1 : -1
        }
        return 1
      })
    })
  }

  function initLinks(currentPath: string, queryParameter: LocationQuery) {
    const config = useRuntimeConfig()
    const ALL_MODE = config.public.allMode // used for debugging purposes mostly. Default is false

    // Configure all the urls for the different modes
    modes.value = {
      all: [
        `/precheckins/${token.value}/customer`,
        `/precheckins/${token.value}/arrival`,
        `/precheckins/${token.value}/pax`,
        `/precheckins/${token.value}/travellers`,
        `/precheckins/${token.value}/services`,
        `/precheckins/${token.value}/checkin`,
      ],
      default: [
        `/precheckins/${token.value}/arrival`,
        `/precheckins/${token.value}/pax`,
        `/precheckins/${token.value}/travellers`,
        `/precheckins/${token.value}/services`,
        `/precheckins/${token.value}/checkin`,
      ],
      easy: [
        `/precheckins/${token.value}/arrival`,
        `/precheckins/${token.value}/travellers`,
        `/precheckins/${token.value}/checkin`,
      ],
      zero: [`/precheckins/${token.value}/customer`],
    }

    // Fetch the mode
    const mode = (ALL_MODE ? 'all' : precheckin.value?.mode) || 'default'
    const modeIndex = modes.value[mode].indexOf(currentPath)

    if (reservation.value?.checkin) {
      const checkinDate = new Date(reservation.value.checkin)
      const now = new Date()
      if (sameDay(checkinDate, now)) {
        // Redirect to error page for arrival today
        steps.value = stepsDefinition.default
        return navigateTo(
          {
            path: `/precheckins/${token.value}/expired`,
            query: queryParameter,
          },
          { redirectCode: 302 },
        )
      } else if (modeIndex === -1) {
        // When accessing an inaccessible page, redirect to the first page of the
        // mode the precheckin is currently in
        return navigateTo(
          { path: modes.value[mode][0], query: queryParameter },
          { redirectCode: 302 },
        )
      }
    }

    // Store the navigation links in the store
    links.value = {
      prev: modes.value[mode][modeIndex - 1] || undefined,
      next: modes.value[mode][modeIndex + 1] || undefined,
    }

    steps.value = stepsDefinition[mode]
  }

  function initTravellers() {
    travellers.value =
      precheckin.value?.travelers?.map((t) => {
        return {
          ...t,
          uuid: nanoid(),
          salutation:
            {
              1: 'M',
              2: 'F',
              M: 'M',
              F: 'F',
              m: 'M',
              f: 'F',
            }[t.salutation || ''] || '',
        }
      }) || []
  }

  async function load() {
    try {
      // Load the precheckin data
      const precheckinData = await getPrecheckin(token.value, null)

      if (precheckinData) {
        // Store the precheckin
        precheckin.value = precheckinData
      }

      // Also load the accommodation data for the precheckin
      const accommodationData = await useAccomm().getAccommodation(
        precheckinData.reservation.accommodation,
        useConfdataStore().language || 'en',
        null,
      )

      if (accommodationData) {
        // Store the accommodation
        accommodation.value = accommodationData
      }

      if (precheckin.value?.pax && precheckin.value?.paxTypes) {
        initPax(precheckin.value.pax, precheckin.value?.paxTypes)
      }

      if (precheckin.value?.travelers) {
        initTravellers()
      }

      if (precheckin.value?.layoutFirst || precheckin.value?.layoutFellows) {
        initLayouts()
      }

      // IHGWEBCC-1502
      // if precheckin contacts contain email address we must check whether it is an Airbnb booking created after 2024-10-14
      if (precheckin.value?.contact?.find((c) => c.type === 'MAIL')) {
        await checkAirbnb()
      } else {
        requestEmail.value = true
      }
    } catch (error) {
      console.error('Error while loading precheckin and accommodation: ', error)
      throw error
    }
  }

  async function getPrecheckin(token: string, params: any) {
    return await $fetch<Precheckin>(
      `/webcc-api/v1/booking/precheckins/${token}`,
      { params },
    )
  }

  async function checkAirbnb() {
    const params = {}
    const data = await uReservation.getReservation(token.value, params)

    if (data.insertDate) {
      const { partnerid } = data
      const createdDate = new Date(data.insertDate).getTime()
      const checkDate = new Date('2024-10-14').getTime()

      if (createdDate > checkDate && partnerid === 'CH1002117') {
        requestEmail.value = true
      }
    }
  }

  function initLayouts() {
    const result: Record<string, PrecheckinField[]> = {}

    for (const [key, value] of Object.entries({
      primary: precheckin.value?.layoutFirst,
      additional: precheckin.value?.layoutFellows,
    })) {
      if (!key || !value) {
        continue
      }
      // Loop over all the fields for the traveller type
      const updatedTravellerType = value.map((field) => {
        const type = mapType(field.name)
        return { ...field, type }
      })

      result[key] = updatedTravellerType
    }
    layouts.value = result
  }

  async function save() {
    await $fetch<Precheckin>(
      `/webcc-api/v1/booking/precheckins/${token.value}`,
      {
        method: 'POST',
        body: precheckin.value,
      },
    ).catch((error) => {
      console.error('Error while saving precheckin')
      throw error
    })
  }

  async function saveContact(data: {
    type: string
    country: string
    contact: string
    contactMail: string
    arrivalDetail: string
  }) {
    await $fetch(`/webcc-api/v1/booking/precheckins/${token.value}/contact`, {
      method: 'POST',
      body: data,
    }).catch((error) => {
      console.error('Error while saving precheckin contact')
      throw error
    })

    if (
      precheckin.value &&
      precheckin.value.contact?.length &&
      precheckin.value.contact[0]
    ) {
      precheckin.value.contact[0].arrivalDetail = data.arrivalDetail
    }

    if (
      precheckin.value &&
      precheckin.value.contact?.length &&
      precheckin.value.contact[1]
    ) {
      precheckin.value.contact[1].arrivalDetail = data.arrivalDetail
    }
  }

  async function guestRegistration(token: string) {
    await $fetch(
      `/webcc-api/v1/booking/precheckins/${token}/guestregistration`,
      {
        method: 'PUT',
      },
    ).catch((error) => {
      console.error('Error while registering guest')
      throw error
    })
  }

  async function checkinConfirm() {
    await $fetch(`/webcc-api/v1/booking/precheckins/${token.value}/confirm`, {
      method: 'POST',
    }).catch((error) => {
      console.error('Error while confirmin checkin')
      throw error
    })
  }

  async function saveCustomer(formdata: PrecheckinCustomer) {
    await $fetch(`/webcc-api/v1/booking/precheckins/${token.value}/customer`, {
      method: 'POST',
      body: formdata,
    }).catch((error) => {
      console.error('Error while saving precheckin customer')
      throw error
    })
  }

  async function savePax(data: Record<string, number>) {
    await $fetch(`/webcc-api/v1/booking/precheckins/${token.value}/pax`, {
      method: 'POST',
      body: data,
    }).catch((error) => {
      console.error('Error while saving precheckin pax')
      throw error
    })
  }

  async function saveTravellers() {
    await $fetch(
      `/webcc-api/v1/booking/precheckins/${token.value}/travellers`,
      {
        method: 'POST',
        body: travellers.value.map((t, i) => ({
          ...t,
          number: (i + 1).toString(),
          birthday: t.birthday ? toDate(t.birthday) : '',
          passportIssueDate: t.passportIssueDate
            ? toDate(t.passportIssueDate)
            : '',
          passportExpDate: t.passportExpDate ? toDate(t.passportExpDate) : '',
        })),
      },
    ).catch((error) => {
      console.error('Error while saving precheckin travellers')
      throw error
    })
  }

  async function saveService(service: {
    code: string
    amount: string
    validFrom?: string
    validTo?: string
  }) {
    const res = await $fetch<UpdateServiceResponse>(
      `/webcc-api/v1/booking/precheckins/${token.value}/services`,
      {
        method: 'POST',
        body: [service],
      },
    ).catch((error) => {
      console.error('Error while saving precheckin service')
      throw error
    })

    if (precheckin.value && res.TotalCostAmountLocally) {
      precheckin.value.onSiteCosts = res.TotalCostAmountLocally
    }

    return res
  }

  return {
    accommodation,
    reservation,
    precheckin,
    travellersListed,
    steps,
    step,
    mode,
    links,
    computedLinks,
    token,
    customer,
    contact,
    travellers,
    pax,
    layouts,
    load,
    save,
    saveContact,
    checkinConfirm,
    guestRegistration,
    saveCustomer,
    savePax,
    saveTravellers,
    saveService,
    initPax,
    initLinks,
    initTravellers,
    initLayouts,
    currentPath,
    requestEmail,
  }
})

/**
 * Maps the proper types to each of the fields based on the name. This is done because
 * the proper API implementatn is not done yet. This will be removed completely eventually.
 *
 * @param { string } fieldName The name of the field which is being mapped.
 * @returns Returns the appropriate type for the field.
 */
function mapType(fieldName: string) {
  const name = fieldName.charAt(0).toLowerCase() + fieldName.slice(1)

  switch (name) {
    case 'salutation':
      return 'WebccRadio'

    case 'birthday':
    case 'passIssueDate':
    case 'passportIssueDate':
    case 'passportExpDate':
    case 'passportExpiryDate':
    case 'passExpDate':
      return 'WebccDatepicker'

    case 'firstname':
    case 'lastname':
    case 'passID':
      return 'WebccInput'

    case 'maritalStatus':
    case 'passportType':
    case 'countryOrigin':
    case 'countryOfBirth':
    case 'country':
      return 'WebccSelect'

    default:
      return 'WebccInput'
  }
}
